import React, { useState, useEffect } from "react";
import "../pages/Style/InterestRateModal.css"; // Ensure to create the appropriate CSS file for styling
import nft from "./Videos/nft.jpg";

const InterestRateModal = ({
  open,
  onClose,
  editInterestRate,
  setEditInterestRate,
  onSaveSuccess,
}) => {
  const [interestRate, setInterestRate] = useState({
    Admin_Fee: "",
    Interest_Fee: "",
    Monthly_Fee: "",
  });

  useEffect(() => {
    if (editInterestRate) {
      setInterestRate({
        Admin_Fee: editInterestRate.Admin_Fee,
        Interest_Fee: editInterestRate.Interest_Fee,
        Monthly_Fee: editInterestRate.Monthly_Fee,
      });
    }
  }, [editInterestRate]);

  const handleInputChange = (e) => {
    setInterestRate({ ...interestRate, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const apiUrl = editInterestRate
        ? `http://localhost:8083/${editInterestRate.Interest_ID}`
        : "http://localhost:8083/"; // Adjust the API endpoint accordingly

      const response = await fetch(apiUrl, {
        method: editInterestRate ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(interestRate),
      });

      if (response.ok) {
        console.log(
          editInterestRate
            ? "Interest rate updated successfully"
            : "Interest rate added successfully"
        );
        alert("Interest rate updated successfully");

        onSaveSuccess();
        setEditInterestRate(null);
        onClose();
      }
    } catch (error) {
      console.error("Error submitting interest rate data:", error.message);
    }
  };

  if (!open) return null;

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainerEdit"
      >
        <img src={nft} alt="/" />
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <h2 style={{ color: "black", marginBottom: "0%", margin: "9%" }}>
            Interest Rate changes
          </h2>
          <div className="content">
            <label htmlFor="Admin_Fee">Admin Fee:</label>
            <input
              type="text"
              name="Admin_Fee"
              value={interestRate.Admin_Fee}
              onChange={handleInputChange}
            />
            <label htmlFor="Interest_Fee">Interest Fee:</label>
            <input
              type="text"
              name="Interest_Fee"
              value={interestRate.Interest_Fee}
              onChange={handleInputChange}
            />
            <label htmlFor="Monthly_Fee">Monthly Fee:</label>
            <input
              type="text"
              name="Monthly_Fee"
              value={interestRate.Monthly_Fee}
              onChange={handleInputChange}
            />
          </div>
          <div className="btnContainer">
            <button
              style={{ marginLeft: "32px", marginBottom: "3%" }}
              className="btn-p green-button decorated-button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestRateModal;
