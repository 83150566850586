import React, { useState, useEffect } from "react";
import "../pages/Style/NotificationModal.css";

const NotificationModal = ({
  isOpen,
  onClose,
  username,
  role,
  notifications = [],
  refreshNotifications,
  onMarkAsDone, // Callback function to handle marking notifications as done
}) => {
  const [copiedId, setCopiedId] = useState(null);

  useEffect(() => {
    if (isOpen && role) {
      console.log("Modal open, fetching notifications...");
      console.log("Username:", username);
      console.log("Role:", role);
    }
  }, [isOpen, username, role]);

  const handleCopyId = (id) => {
    navigator.clipboard.writeText(id);
    setCopiedId(id);
    setTimeout(() => {
      setCopiedId(null);
    }, 1500); // Reset copiedId after 1.5 seconds
  };

  const markAsDone = async (ID_Number_Passport) => {
    const confirm = window.confirm(
      "Are you sure you want to mark this status as Done?"
    );
    if (!confirm) return;

    try {
      const response = await fetch("http://localhost:8082/update-status", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID_Number_Passport }),
      });

      if (response.ok) {
        console.log(
          `Notification with ID ${ID_Number_Passport} marked as done.`
        );
        refreshNotifications(); // Refresh notifications after marking as done
        onMarkAsDone(ID_Number_Passport); // Notify parent component
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Function to filter out marked notifications
  const filterMarkedNotifications = () => {
    return notifications.filter(
      (notification) => !notification.isDone // Assuming there's a property isDone in your notification object
    );
  };

  return (
    <div>
      <div className={`modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content">
          <span className="close-btn" onClick={onClose}>
            &times;
          </span>
          <h3 style={{ marginBottom: "10px" }}>All your notifications</h3>
          {notifications.length > 0 ? (
            <div className="ScrollMessagesNotification">
              {filterMarkedNotifications().map((notification, index) => (
                <div className="MessagesNotification" key={index}>
                  <button
                    className="done-btn"
                    onClick={() => markAsDone(notification.ID_Number_Passport)}
                  >
                    Done
                  </button>
                  <h6>
                    ID Number:{" "}
                    <span
                      onClick={() =>
                        handleCopyId(notification.ID_Number_Passport)
                      }
                      style={{
                        userSelect: "text",
                        cursor: "pointer",
                        borderRadius: "5px",
                        backgroundColor:
                          copiedId === notification.ID_Number_Passport
                            ? "#3cb371"
                            : "",
                      }}
                    >
                      {notification.ID_Number_Passport}
                    </span>
                  </h6>
                  <h6>Full Names: {notification.Full_Names}</h6>
                  <h6>Status: {notification.Status}</h6>
                  <h6>Comment:</h6>
                  <p
                    style={{
                      fontSize: "11px",
                      backgroundColor: "#f0fff0",
                      padding: "10px",
                      fontWeight: "bold",
                      borderRadius: "10px",
                    }}
                  >
                    {notification.Comment}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p>No notifications available</p>
          )}
        </div>
      </div>
      {copiedId && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#3cb371",
            padding: "5px 10px",
            borderRadius: "5px",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
            zIndex: "9999",
          }}
        >
          ID Copied: {copiedId}
        </div>
      )}
    </div>
  );
};

export default NotificationModal;
