import React, { useState, useEffect } from "react";
import { Edit } from "react-feather";
import "react-responsive-modal/styles.css";
import InterestRateModal from "../Components/InterestRateModal.jsx";
import "./Style/Billing.css";

const Billing = () => {
  const [interestRates, setInterestRates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editInterestRate, setEditInterestRate] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state

  useEffect(() => {
    fetchInterestRates();
  }, []);

  const fetchInterestRates = () => {
    setLoading(true); // Start loading
    fetch("http://localhost:8083/")
      .then((response) => response.json())
      .then((data) => setInterestRates(data))
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoading(false)); // Stop loading
  };

  const openModal = (interestRate) => {
    setEditInterestRate(interestRate);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditInterestRate(null);
  };

  const onSaveSuccess = () => {
    fetchInterestRates(); // Refetch interest rates after saving
  };

  return (
    <div className="massage-container">
      <div className="title">Billing</div>

      <div className="table-container">
        {loading ? (
          <div className="loading-container">
            <div className="spinner"></div>
            <span>Loading...</span>
          </div>
        ) : (
          <table className="table_billing">
            <thead>
              <tr>
                <th>#</th>
                <th>Admin Fee</th>
                <th>Interest Fee</th>
                <th>Monthly Fee</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {interestRates.map((rate, index) => (
                <tr key={rate.Interest_ID}>
                  <td>{index === 0 ? "Normal Rate" : "Birth Month Rate"}</td>
                  <td>{rate.Admin_Fee}</td>
                  <td>{rate.Interest_Fee}</td>
                  <td>{rate.Monthly_Fee}</td>
                  <td>
                    <div className="button-container">
                      <button
                        className="edit-button"
                        onClick={() => openModal(rate)}
                      >
                        <Edit size={15} />
                        <span>Edit</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <InterestRateModal
        open={isModalOpen}
        onClose={closeModal}
        editInterestRate={editInterestRate}
        setEditInterestRate={setEditInterestRate}
        onSaveSuccess={onSaveSuccess}
      />
    </div>
  );
};

export default Billing;
