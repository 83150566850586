import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaPhone,
  FaPalette,
  FaMoneyBill,
  FaUser,
  FaBell,
  FaTimes,
} from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCalculator, BsCartCheck } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "./Videos/logo5_30_201219.png";
import SidebarMenu from "./SidebarMenu";
import NotificationModal from "../Components/NotificationModal.jsx";
import "./SideBar.css";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome className="icon" />,
    roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
  },
  {
    path: "/users",
    name: "Users",
    icon: <FaUser className="icon" />,
    roles: ["CEO"], // Only accessible by CEO
  },
  {
    path: "/calculate",
    name: "Calculate",
    icon: <BsCalculator className="icon" />,
    roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
  },
  {
    path: "/order",
    name: "Order",
    icon: <BsCartCheck className="icon" />,
    roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
  },
  {
    path: "/messages",
    name: "Messages",
    icon: <MdMessage className="icon" />,
    roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: <BiAnalyse className="icon" />,
    roles: ["CEO"], // Only accessible by CEO
  },
  {
    path: "/file-manager",
    name: "File Manager",
    icon: <AiTwotoneFileExclamation className="icon" />,
    roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
    subRoutes: [
      {
        path: "/file-manager/profile",
        name: "Profile",
        icon: <FaUser className="icon" />,
        roles: ["CEO"], // Only accessible by CEO
      },
      {
        path: "/file-manager/contact",
        name: "Contact",
        icon: <FaPhone className="icon" />,
        roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
      },
      {
        path: "/file-manager/billing",
        name: "Billing",
        icon: <FaMoneyBill className="icon" />,
        roles: ["CEO"], // Only accessible by CEO
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    icon: <BiCog className="icon" />,
    roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
    subRoutes: [
      {
        path: "/settings/profile",
        name: "Profile",
        icon: <FaUser className="icon" />,
        roles: ["CEO"], // Only accessible by CEO
      },
      {
        path: "/settings/theme",
        name: "Theme",
        icon: <FaPalette className="icon" />,
        roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
      },
      {
        path: "/settings/notifications",
        name: "Notifications",
        icon: <FaBell className="icon" />,
        roles: ["CEO", "Admin", "Consultant"], // Accessible by all roles
      },
    ],
  },
];

const SideBar = ({ children, username, role }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const prevNotificationsRef = useRef([]);
  const audioRef = useRef(null);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          console.error("Notification permission denied");
        }
      });
    }
  }, []);

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 10000); // Poll every 10 seconds
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [username, role]);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(
        `http://localhost:8082/notifications?admin=${username}&role=${role}`,
        { method: "GET" }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setNotificationCount(data.length);
        handleNewNotifications(data);
      } else {
        console.error("Fetched data is not an array:", data);
        setNotificationCount(0);
        setNotifications([]);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotificationCount(0);
      setNotifications([]);
    }
  };

  const handleNewNotifications = (newData) => {
    const newNotifications = newData.filter(
      (newNotification) =>
        !prevNotificationsRef.current.some(
          (prevNotification) =>
            prevNotification.ID_Number_Passport ===
            newNotification.ID_Number_Passport
        )
    );

    if (newNotifications.length > 0) {
      newNotifications.forEach((notification) => {
        showNotification(notification);
      });

      prevNotificationsRef.current = newData;
      setNotifications(newData);
    }
  };

  const showNotification = (notification) => {
    if (Notification.permission === "granted") {
      const notificationInstance = new Notification(`New Notification`, {
        body: `Full Names: ${notification.Full_Names}\nStatus: ${notification.Status}\nComment: ${notification.Comment}`,
        icon: Logo, // Add your logo or any image URL
        badge: Logo, // Optional badge image
        tag: notification.ID_Number_Passport, // Tag to identify the notification
        renotify: true, // Renotify if the same tag is used
        requireInteraction: true, // Keep the notification until the user interacts
      });

      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
      }

      const notificationTimeout = setTimeout(() => {
        sendFollowUpNotification(notification);
      }, 10 * 60 * 1000); // 10 minutes

      notificationInstance.onclick = () => {
        clearTimeout(notificationTimeout); // Clear the timeout if the notification is clicked
        notificationInstance.close(); // Close the notification
      };
    }
  };

  const sendFollowUpNotification = (notification) => {
    if (Notification.permission === "granted") {
      new Notification(`Follow-Up Notification`, {
        body: `Reminder: Full Names: ${notification.Full_Names}\nStatus: ${notification.Status}\nComment: ${notification.Comment}`,
        icon: Logo, // Add your logo or any image URL
        badge: Logo, // Optional badge image
        tag: `follow-up-${notification.ID_Number_Passport}`, // Unique tag for the follow-up
        renotify: true, // Renotify if the same tag is used
        requireInteraction: true, // Keep the notification until the user interacts
      });
    }
  };

  const toggle = () => setIsOpen(!isOpen);

  const inputAnimation = {
    hidden: { width: 0, padding: 0, transition: { duration: 0.2 } },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: { duration: 0.2 },
    },
  };

  const showAnimation = {
    hidden: { width: 0, opacity: 0, transition: { duration: 0.5 } },
    show: { opacity: 1, width: "auto", transition: { duration: 0.5 } },
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleMarkAsDone = (ID_Number_Passport) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter(
        (notification) => notification.ID_Number_Passport !== ID_Number_Passport
      )
    );
  };

  // Filter routes based on CEO role
  const filteredRoutes = routes
    .map((route) => {
      if (route.roles.includes(role)) {
        if (route.subRoutes) {
          const filteredSubRoutes = route.subRoutes.filter((subRoute) =>
            subRoute.roles.includes(role)
          );
          return { ...route, subRoutes: filteredSubRoutes };
        }
        return route;
      }
      return null;
    })
    .filter((route) => route !== null);

  return (
    <>
      <div className="main-container">
        <div className="login-as">
          <p style={{ marginBottom: "3px" }}>Signed in: {username}</p>
          <hr />
          <div style={{ marginTop: "7px" }}>
            <div style={{ cursor: "pointer" }}>
              <p className="NotificationNumber">
                {notificationCount > 9 ? "9+" : notificationCount}
              </p>
              <MdMessage className="icon" onClick={toggleModal} />
            </div>
            <p
              style={{
                fontSize: "10px",
                marginLeft: "24px",
                marginTop: "-16px",
                color: "#00ffff",
              }}
            >
              Staff messages
            </p>
          </div>
        </div>
        <motion.div
          animate={{
            width: isOpen ? "190px" : "40px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <>
                  <motion.h1
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo"
                  >
                    <motion.img
                      src={Logo}
                      alt="Your Alt Text"
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="your-image-class"
                      style={{
                        width: "50px",
                        height: "50px",
                        top: "-10px",
                        left: "-10px",
                        margin: "0",
                      }}
                    />
                  </motion.h1>
                </>
              )}
            </AnimatePresence>

            <div className="bars">
              {isOpen ? (
                <FaTimes onClick={toggle} className="icon" />
              ) : (
                <FaBars onClick={toggle} className="icon" />
              )}
            </div>
          </div>
          <div className="search">
            <div className="search_icon">
              <BiSearch className="icon" />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div>
          <section className="routes">
            {filteredRoutes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    key={index}
                  />
                );
              }
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                  onClick={() => setIsOpen(false)}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
        <main>{children}</main>
      </div>
      {modalVisible && (
        <NotificationModal
          isOpen={modalVisible}
          onClose={toggleModal}
          notifications={notifications}
          username={username}
          role={role} // Pass role to NotificationModal
          refreshNotifications={fetchNotifications}
          onMarkAsDone={handleMarkAsDone} // Pass callback function to NotificationModal
        />
      )}
      <audio ref={audioRef} src="/notification.mp3" preload="auto" />
    </>
  );
};

export default SideBar;
