import React, { useState, useEffect } from "react";
import nft from "./Videos/nft.jpg";
import "../pages/Style/EditModal.css";

const PlaceOrderModal = ({
  open,
  onClose,
  editClient,
  setEditClient,
  onSaveSuccess,
}) => {
  const [user, setUser] = useState({
    Client_Code: "",
    Nu_Pay_Card_Num: "",
    Status: "Please Select",
    Comment: "",
  });

  useEffect(() => {
    if (editClient) {
      setUser({
        Client_Code: editClient.Client_Code,
        Nu_Pay_Card_Num: editClient.Nu_Pay_Card_Num,
        Status: editClient.Status,
        Comment: editClient.Comment,
      });
    }
  }, [editClient]);

  const positionOptions = ["Please Select", "Loan Approval", "Top Up Approval"];

  const [idFile, setIdFile] = useState(null);
  const [bankStatementFile, setBankStatementFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    // Handle regular inputs
    if (type !== "file") {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    } else {
      // Handle file inputs
      if (name === "IDFile") {
        setIdFile(files[0]); // Update idFile state
      } else if (name === "BankStatementFile") {
        setBankStatementFile(files[0]); // Update bankStatementFile state
      }
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate input fields
      if (
        !user.Client_Code ||
        !user.Nu_Pay_Card_Num ||
        !user.Status ||
        !user.Comment
      ) {
        alert("Please fill in all required fields.");
        return;
      }

      // Create FormData object
      const formData = new FormData();

      // Append user data to FormData object
      formData.append("Client_Code", user.Client_Code);
      formData.append("Nu_Pay_Card_Num", user.Nu_Pay_Card_Num);
      formData.append("Status", user.Status);
      formData.append("Comment", user.Comment);

      // Append files to FormData object
      if (idFile) {
        formData.append("ID_Copy", idFile);
      }
      if (bankStatementFile) {
        formData.append("Statement_Copy", bankStatementFile);
      }

      const apiUrl = editClient
        ? `http://localhost:8082/customer/${editClient.Customer_ID}`
        : "http://localhost:8082/add-client";

      const requestOptions = {
        method: editClient ? "PUT" : "POST",
        body: formData,
      };

      const response = await fetch(apiUrl, requestOptions);

      if (response.ok) {
        alert("Client updated successfully");
        onSaveSuccess();
        setEditClient(null);
        onClose();
      } else {
        const errorMessage = await response.text();
        alert("Server Error: " + errorMessage);
      }
    } catch (error) {
      alert("Error submitting client data: " + error.message);
    }
  };

  if (!open) return null;

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer1"
      >
        <img src={nft} alt="/" />
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <h2 style={{ color: "black", marginBottom: "0%", margin: "6%" }}>
            Place Order For Client
          </h2>
          <div className="content">
            <label htmlFor="">Client Code</label>
            <input
              readOnly
              type="text"
              name="Client_Code"
              placeholder="Client_Code"
              value={user.Client_Code}
              onChange={handleInputChange}
            />
            <label htmlFor="">Nu_Pay Card Num</label>
            <input
              readOnly
              type="text"
              name="Nu_Pay_Card_Num"
              placeholder="Nu_Pay_Card_Num"
              value={user.Nu_Pay_Card_Num}
              onChange={handleInputChange}
            />
            <div className="inputColumns">
              <div className="inputColumn">
                <label htmlFor="">Status</label>
                <select
                  name="Status"
                  value={user.Status}
                  onChange={handleInputChange}
                >
                  {positionOptions.map((position, index) => (
                    <option key={index} value={position}>
                      {position}
                    </option>
                  ))}
                </select>
              </div>
              <div className="PDF-container2">
                <div className="inputColumn">
                  <label htmlFor="">Upload ID (PDF)</label>
                  <hr />
                  <input
                    type="file"
                    name="IDFile"
                    className="PDF-input"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputColumn">
                  <label htmlFor="">Upload Bank Statement (PDF)</label>
                  <hr />
                  <input
                    type="file"
                    name="BankStatementFile"
                    className="PDF-input"
                    onChange={handleInputChange}
                  />
                </div>
                <label htmlFor="">Comment</label> {/* New input label */}
                <input
                  type="text"
                  name="Comment"
                  value={user.Comment}
                  style={{ height: "90px" }}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="btnContainer">
            <button
              style={{
                marginTop: "15%",
                marginLeft: "32px",
                marginBottom: "3%",
              }}
              className="btn-p green-button decorated-button"
              onClick={handleSubmit}
            >
              {editClient ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrderModal;
