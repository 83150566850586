import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import profile from "./Videos/logo5_30_201219.png";
import Video from "../pages/Image/video.mp4";
import Modal from "./SigninModal.jsx";

function LoginUi({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editClient, setEditClient] = useState(null);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    const requiredFields = ["email", "password"];

    for (const field of requiredFields) {
      if (!getFieldValue(field)) {
        alert(`${field.charAt(0).toUpperCase() + field.slice(1)} is required`);
        return;
      }
    }

    fetch("http://localhost:8085/validate-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(error.error || error.message);
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        onLogin(data.username, data.role); // Pass the username and role to onLogin
        navigate(`/#Signed%20in%3A%20${encodeURIComponent(data.username)}`);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const getFieldValue = (field) => {
    switch (field) {
      case "email":
        return email.trim();
      case "password":
        return password.trim();
      default:
        return null;
    }
  };

  const handleSaveSuccess = () => {
    setOpenModal(false);
    setEditClient(null);
  };

  return (
    <div className="login-main">
      <video autoPlay muted loop className="login-video">
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="login-sub-main">
        <div>
          <div className="login-imgs">
            <div className="login-container-image">
              <img src={profile} alt="profile" className="login-profile" />
            </div>
          </div>
          <div>
            <h1>Welcome back</h1>
            <div>
              <input
                type="text"
                placeholder="user name"
                className="login-input login-name"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="login-second-input">
              <input
                type="password"
                placeholder="password"
                className="login-input login-name"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div>
              <button onClick={handleLogin} className="login-button">
                Login
              </button>
            </div>

            {password === "123" && (
              <p>
                <button
                  className="login-link-btn"
                  onClick={() => console.log("Forgot password?")}
                >
                  Forgot password?
                </button>
                <span>Or</span>
                <button
                  className="login-link-btn"
                  style={{ backgroundColor: "green" }}
                  onClick={() => {
                    setEditClient();
                    setOpenModal(true);
                  }}
                >
                  Sign Up
                </button>
              </p>
            )}

            {password !== "123" && (
              <p>
                <button
                  className="login-link-btn"
                  onClick={() => console.log("Forgot password?")}
                >
                  Forgot password?
                </button>
              </p>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditClient(null);
        }}
        editClient={editClient}
        setEditClient={setEditClient}
        onSaveSuccess={handleSaveSuccess}
      />
    </div>
  );
}

export default LoginUi;
