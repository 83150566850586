import React, { useState, useEffect, useCallback } from "react";
import "./Style/Order.css";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import PlaceOrderModal from "../Components/PlaceOrderModal.jsx";
import { useLocation } from "react-router-dom";

const Order = ({ username }) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showProcessingOnly, setShowProcessingOnly] = useState(false);
  const [processingCount, setProcessingCount] = useState(0);
  const [editClient, setEditClient] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [showApprovedOnly, setShowApprovedOnly] = useState(false);
  const [totalRepayment, setTotalRepayment] = useState(0);
  const [isLoggedIn] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      const hash = `#Signed%20in%3A%20${encodeURIComponent(username)}`;
      if (window.location.hash !== hash) {
        window.location.hash = hash;
      }
    }
  }, [location, isLoggedIn, username]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `http://localhost:8082/customer/${username}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setUserData(data);

      const processingOrdersCount = data.filter(
        (user) =>
          user.Status === "Processing Loan" ||
          user.Status === "Processing Top Up"
      ).length;
      setProcessingCount(processingOrdersCount);

      const approvedOrdersCount = data.filter(
        (user) =>
          user.Status === "Loan Approved" || user.Status === "Top Up Approved"
      ).length;
      setApprovedCount(approvedOrdersCount);

      const totalRepayment = data.reduce(
        (total, user) => total + (user.Repayment_Amount || 0),
        0
      );
      setTotalRepayment(totalRepayment);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  }, [username]);

  const handleSaveSuccess = () => {
    fetchData();
    setOpenModal(false);
    setEditClient(null);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleViewOrderClick = () => {
    setShowProcessingOnly(!showProcessingOnly);
  };

  const handleViewOrderApprovedClick = () => {
    setShowApprovedOnly(!showApprovedOnly);
  };

  const filteredUserData = userData.filter((user) => {
    if (showProcessingOnly) {
      return (
        user.Status === "Processing Loan" || user.Status === "Processing Top Up"
      );
    } else if (showApprovedOnly) {
      return (
        user.Status === "Loan Approved" || user.Status === "Top Up Approved"
      );
    } else {
      return (
        user.Status === "Top Up Not Approved" ||
        user.Status === "Loan Not Approved"
      );
    }
  });

  return (
    <div className="massage-container">
      <div className="title">Order</div>
      <div className="Order-container">
        <Card style={{ width: "19rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">Order</Card.Title>
            <Card.Subtitle className="container">
              Number of your Order
            </Card.Subtitle>
            <button
              className="btn-p green-button decorated-button"
              type="button"
              style={{ marginTop: "18px" }}
              onClick={handleViewOrderClick}
            >
              <span>View-Order</span>
            </button>
          </Card.Body>
          <Card.Text className="customer-number">{processingCount}</Card.Text>
        </Card>
      </div>
      <div className="TotalAmountApproved-container">
        <Card style={{ width: "18rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">Order Approved</Card.Title>
            <Card.Subtitle className="container">
              Number of your Order Approved
            </Card.Subtitle>
            <button
              className="btn-p green-button decorated-button"
              type="button"
              style={{ marginTop: "18px" }}
              onClick={handleViewOrderApprovedClick}
            >
              <span>View-Order</span>
            </button>
          </Card.Body>
          <Card.Text className="customer-number">{approvedCount}</Card.Text>
        </Card>
      </div>
      <div className="TotalAmountHelper-container">
        <Card style={{ width: "18rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">Amount of Orders</Card.Title>
            <Card.Subtitle className="container">
              Total Amount of All Orders For this month
            </Card.Subtitle>
            <Card.Text className="text-amount">
              {loading ? (
                <div className="loading-container-text">
                  <div className="spinner"></div>
                </div>
              ) : (
                `R ${totalRepayment.toFixed(2)}`
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className="TotalAmountCost-container">
        <Card style={{ width: "10rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">Amount</Card.Title>
            <Card.Subtitle className="container">Total Amount</Card.Subtitle>
            <Card.Text className="text-amount">
              {loading ? (
                <div className="loading-container-text">
                  <div className="spinner"></div>
                </div>
              ) : (
                `R ${totalRepayment.toFixed(2)}`
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className="TableOrder-container">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Customer Names</th>
              <th>Client Codes</th>
              <th>Nu_Pay_Card Num</th>
              <th>Loan Amount</th>
              <th>Top Up</th>
              <th>Status</th>
              <th>Comments</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : (
            <tbody>
              {filteredUserData.length > 0 ? (
                filteredUserData.map((user) => (
                  <tr key={user.Customer_ID}>
                    <td>{user.Full_Names}</td>
                    <td>{user.Client_Code}</td>
                    <td>{user.Nu_Pay_Card_Num}</td>
                    <td>R {user.Loan_Amount || "0.00"}</td>
                    <td>R {user.Top_Up || "0.00"}</td>
                    <td
                      style={{
                        color:
                          user.Status === "New Customer"
                            ? "darkgreen"
                            : user.Status === "Processing Loan" ||
                              user.Status === "Processing Top Up"
                            ? "yellow"
                            : user.Status === "Loan Approved" ||
                              user.Status === "Top Up Approved"
                            ? "#7fff00"
                            : "red",
                      }}
                    >
                      {user.Status}
                    </td>
                    <td>{user.Comment}</td>
                    <td>
                      <button
                        className="btn-p green-button decorated-button"
                        onClick={() => {
                          setEditClient(user);
                          setOpenModal(true);
                        }}
                      >
                        Place Order
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No data available</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </div>
      <PlaceOrderModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditClient(null);
        }}
        editClient={editClient}
        setEditClient={setEditClient}
        onSaveSuccess={handleSaveSuccess}
      />
    </div>
  );
};

export default Order;
