import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import "./Style/Analytics.css";
import Modal from "../Components/ViewEditModal.jsx";

const Analytics = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editClient, setEditClient] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [additionalUserData, setAdditionalUserData] = useState(null);
  const [showEmptyClients, setShowEmptyClients] = useState(false);

  const noCodeAndNumCustomers = userData.filter(
    (user) => !user.Client_Code && !user.Nu_Pay_Card_Num
  ).length;

  const totalAmountSum = userData.reduce((total, user) => {
    const amount = parseFloat(user.Loan_Amount) || 0;
    const topUp = parseFloat(user.Top_Up) || 0;
    return total + amount + topUp;
  }, 0);

  const totalOutstandingAmount = userData.reduce(
    (total, user) => total + parseFloat(user.Repayment_Amount || 0),
    0
  );

  const fetchPdfById = async (customerId, type) => {
    try {
      const response = await fetch(
        `http://localhost:8082/pdf/${customerId}/${type}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }
      const data = await response.blob(); // Fetch PDF as Blob
      return data;
    } catch (error) {
      console.error("Error fetching PDF:", error);
      return null;
    }
  };

  const handleViewID = async (customerId) => {
    try {
      const blobData = await fetchPdfById(customerId, "ID");
      if (blobData) {
        const pdfUrl = URL.createObjectURL(blobData); // Create object URL for Blob
        window.open(pdfUrl, "_blank"); // Open PDF in new tab
      } else {
        console.error("PDF data not found");
        alert("Please view the Client Information to view ID");
      }
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
      alert("Failed to open PDF. Please check if the PDF format is supported.");
    }
  };

  const handleViewStatement = async (customerId) => {
    try {
      const blobData = await fetchPdfById(customerId, "Statement");
      if (blobData) {
        const pdfUrl = URL.createObjectURL(blobData); // Create object URL for Blob
        window.open(pdfUrl, "_blank"); // Open PDF in new tab
      } else {
        console.error("PDF data not found");
        alert("Please view the Client Information to View Statement");
      }
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
      alert("Failed to open PDF. Please check if the PDF format is supported.");
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      console.log(`Selected user ID: ${selectedUserId}`);
      // Additional logic based on the selected user ID
    }
  }, [selectedUserId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch("http://localhost:8082/");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveSuccess = () => {
    fetchData();
    setOpenModal(false);
    setEditClient(null);
  };

  const handleView = (userId) => {
    // Set the selected user ID when the "View" button is clicked
    setSelectedUserId(userId);
    // Fetch and set additional user data
    const user = userData.find((user) => user.Customer_ID === userId);
    setAdditionalUserData(user);
  };

  const handleFilterEmptyClients = () => {
    setShowEmptyClients(!showEmptyClients); // Toggle the state to show or hide empty clients
  };

  userData
    .filter(
      (user) => showEmptyClients || (!user.Client_Code && !user.Nu_Pay_Card_Num) // Apply filter condition based on state
    )
    .filter(
      (user) =>
        user.Status === "Loan Approval" || user.Status === "Top Up Approval"
    );

  return (
    <div className="analytics-container">
      <div className="title">Analytics</div>
      <div className="New-container">
        <Card style={{ width: "18rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">New Costumers</Card.Title>
            <Card.Subtitle className="container">
              Number of costumers
            </Card.Subtitle>
            <button
              className="btn-p green-button decorated-button"
              type="button"
              onClick={handleFilterEmptyClients}
              style={{ marginTop: "18px" }}
            >
              <span>View-costumer</span>
            </button>
          </Card.Body>
          <Card.Text className="customer-number">
            {noCodeAndNumCustomers}
          </Card.Text>
        </Card>
      </div>
      <div className="TotalAmount-container">
        <Card style={{ width: "18rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">Total Amount</Card.Title>
            <Card.Subtitle className="container">
              Total Amount of customers
            </Card.Subtitle>
            <Card.Text className="text-amount">
              {loading ? (
                <div className="loading-container-text">
                  <div className="spinner"></div>
                </div>
              ) : (
                `R ${totalAmountSum.toFixed(2)}`
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="OutdatingAmount-container">
        <Card style={{ width: "18rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">Outstanding Amount</Card.Title>
            <Card.Subtitle className="container">
              Outstanding Amount of customers
            </Card.Subtitle>
            <Card.Text className="text-amount">
              {loading ? (
                <div className="loading-container-text">
                  <div className="spinner"></div>
                </div>
              ) : (
                `R ${totalOutstandingAmount.toFixed(2)}`
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className="Output-container">
        <Card style={{ width: "15rem", height: "8rem" }}>
          <Card.Body>
            <Card.Title className="Card-title">Output</Card.Title>
            <Card.Subtitle className="container">
              View ID and Statement
            </Card.Subtitle>
            <button
              className="btn-p green-button decorated-button"
              onClick={() => handleViewID(selectedUserId)}
            >
              View ID
            </button>
            <button
              className="btn-p blue-button"
              onClick={() => handleViewStatement(selectedUserId)}
            >
              View Statement
            </button>
          </Card.Body>
        </Card>
      </div>
      <Card
        className="Card"
        style={{ width: "22rem", backgroundColor: "rgba(248, 242, 242, 0.4)" }}
      >
        <Card.Img
          variant="top"
          style={{ width: "149px", height: "120px", borderRadius: "3px" }}
          src={
            additionalUserData && additionalUserData.Picture
              ? additionalUserData.Picture.url
              : "https://www.shutterstock.com/image-vector/silhouette-vector-icon-upper-body-600w-2298564235.jpg"
          }
          onError={(e) => {
            console.error("Error loading image:", e.target.src);
            e.target.src =
              "https://www.shutterstock.com/image-vector/silhouette-vector-icon-upper-body-600w-2298564235.jpg";
          }}
        />

        <Card.Body>
          <Card.Title style={{ marginBottom: "0%", fontSize: "25px" }}>
            Client Information
          </Card.Title>
          <hr />
          {additionalUserData && (
            <Card.Text className="card-text-spacing">
              <div>
                <h5>Customer Names: {additionalUserData.Full_Names} </h5>
                <h5>ID/Passport: {additionalUserData.ID_Number_Passport}</h5>
                <h5>
                  Loan Amount: R {additionalUserData.Loan_Amount || "0.00"}
                </h5>
                <h5>Top Up Amount: R {additionalUserData.Top_Up || "0.00"} </h5>
                <h5>
                  Outstanding Amount: R{" "}
                  {additionalUserData.Repayment_Amount || "0.00"}
                </h5>
                <h5>
                  Outstanding Affordability: R{" "}
                  {additionalUserData.Outstanding_Affordability || "0.00"}
                </h5>
                <hr />
                <h5>Comment: {additionalUserData.Comment}</h5>
              </div>
            </Card.Text>
          )}
        </Card.Body>
      </Card>
      <div className="Table-container">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>Customer Names</th>
              <th>Client Codes</th>
              <th>Nu_Pay_Card Num</th>
              <th>Status</th>
              <th>Comment</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : (
            <tbody>
              {userData.length > 0 ? (
                userData
                  .filter(
                    (user) =>
                      showEmptyClients ||
                      (!user.Client_Code && !user.Nu_Pay_Card_Num) // Apply filter condition based on state
                  )
                  .filter(
                    (user) =>
                      user.Status === "Loan Approval" ||
                      user.Status === "Top Up Approval" ||
                      user.Status === "New Customer"
                  )
                  .map((user) => (
                    <tr key={user.Customer_ID}>
                      <td>{user.Full_Names}</td>
                      <td>{user.Client_Code}</td>
                      <td>{user.Nu_Pay_Card_Num}</td>
                      <td
                        style={{
                          color:
                            user.Status === "New Customer"
                              ? "#008b8b"
                              : "orange",
                        }}
                      >
                        {user.Status}
                      </td>
                      <td>{user.Comment}</td>
                      <td>
                        <button
                          className="btn-p green-button decorated-button"
                          onClick={() => handleView(user.Customer_ID)}
                        >
                          View
                        </button>
                        <button
                          className="btn-p blue-button"
                          onClick={() => {
                            setEditClient(user);
                            setOpenModal(true);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan="5">No data available</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setEditClient(null);
        }}
        editClient={editClient}
        setEditClient={setEditClient}
        onSaveSuccess={handleSaveSuccess}
      />
    </div>
  );
};

export default Analytics;
