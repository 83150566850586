import React, { useState, useEffect, useMemo } from "react";
import "./Style/User.css";
import { PlusCircle, Edit, Trash2 } from "react-feather";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import nft from "../Components/Videos/nft.jpg";

const Setting = () => {
  const blankUser = useMemo(
    () => ({
      Branch_Name: "",
      Username: "",
      Email_Address: "",
      Password: "",
      Position: "",
      Active: false,
    }),
    []
  );

  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("Add");
  const [userData, setUserData] = useState([]);
  const [user, setUser] = useState(blankUser);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedModalState =
      JSON.parse(localStorage.getItem("modalState")) || false;
    const savedUser = JSON.parse(localStorage.getItem("user")) || blankUser;
    const savedEditIndex =
      JSON.parse(localStorage.getItem("editIndex")) || null;

    setOpen(savedModalState);
    setUser(savedUser);
    setEditIndex(savedEditIndex);

    fetchData();
  }, [blankUser]);

  useEffect(() => {
    localStorage.setItem("modalState", JSON.stringify(open));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("editIndex", JSON.stringify(editIndex));
  }, [open, user, editIndex]);

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => {
    setOpen(false);
    setAction("Add");
    setUser(blankUser);
    setEditIndex(null);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch("http://localhost:8081/");
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const addUser = async () => {
    try {
      setLoading(true);
      const response = await fetch("http://localhost:8081/addstaff", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        await fetchData();
        onCloseModal();
        alert("User added successfully");
      } else {
        const errorResult = await response.json();
        console.error("Error adding user:", errorResult);
        alert("Error adding user");
      }
    } catch (error) {
      console.error("Error adding user:", error);
      alert("Error adding user");
    } finally {
      setLoading(false);
    }
  };

  const editUser = (index) => {
    setAction("Edit");
    const selectedUser = userData.find((x, i) => i === index);
    setUser(selectedUser);
    setEditIndex(index);
    onOpenModal();
  };

  const updateUser = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `http://localhost:8081/updatestaff/${user.Username}`, // Use the user's username
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (!response.ok) {
        console.error(
          `Failed to update user. Server returned status ${response.status}. Response text:`,
          await response.text()
        );
        return;
      }

      const result = await response.json();
      console.log("Result:", result);

      await fetchData();
      onCloseModal();
      alert("User information updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (username) => {
    try {
      setLoading(true);
      const encodedUsername = encodeURIComponent(username);

      const response = await fetch(
        `http://localhost:8081/deletestaff/${encodedUsername}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        console.error(
          `Failed to delete user. Server returned status ${response.status}. Response text:`,
          await response.text()
        );
        return;
      }

      const result = await response.json();
      console.log(result);
      await fetchData();
      alert("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
    }
  };

  const positionOptions = ["Please Select", "Admin", "CEO", "Consultant"];
  return (
    <div className="user-container">
      <div className="title">User</div>
      <div>
        <div className="toolbar">
          <button
            className="btn-p green-button decorated-button"
            type="button"
            onClick={onOpenModal}
          >
            <PlusCircle size={10}></PlusCircle>
            <span>Add</span>
          </button>
        </div>
        <br></br>
        <div className="table-container">
          {loading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <span>Loading...</span>
            </div>
          ) : (
            <table
              className="table"
              style={{ marginLeft: "10px", marginBottom: "10px" }}
            >
              <thead>
                <tr>
                  <th>Branch Name</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Position</th>
                  <th>Active Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userData.length > 0 &&
                  userData.map((user, index) => (
                    <tr key={index}>
                      <td>{user.Branch_Name}</td>
                      <td>{user.Username}</td>
                      <td>{user.Email_Address}</td>
                      <td>{user.Position}</td>
                      <td>{user.Active ? "Active" : "Inactive"}</td>
                      <td>
                        <div className="button-container">
                          <button
                            className="edit-button"
                            onClick={() => editUser(index)}
                          >
                            <Edit size={15} />
                            <span>Edit</span>
                          </button>
                          <button
                            className="delete-button"
                            onClick={() => deleteUser(user.Username)}
                          >
                            <Trash2 size={15} />
                            <span>Delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <div classNames="modal-Container">
          <Modal open={open} onClose={onCloseModal} centered>
            <div className="imgContainer">
              <img
                style={{
                  height: "481px",
                  position: "absolute",
                  width: "80%",
                  margin: "0px",
                }}
                src={nft}
                alt="/"
              />
            </div>
            <h2 className="h2">{action} User</h2>
            <div className="form1">
              <label htmlFor="">Branch Name</label>
              <input
                type="text"
                value={user.Branch_Name}
                placeholder={"Enter Branch Name"}
                onChange={(e) =>
                  setUser({ ...user, Branch_Name: e.target.value })
                }
              />
              <label htmlFor="">User Name</label>
              <input
                type="text"
                value={user.Username}
                placeholder={"Enter User Name"}
                onChange={(e) => setUser({ ...user, Username: e.target.value })}
              />
              <label htmlFor="">Email</label>
              <input
                type="text"
                value={user.Email_Address}
                placeholder={"Enter Email"}
                onChange={(e) =>
                  setUser({ ...user, Email_Address: e.target.value })
                }
              />
              <label htmlFor="">Position</label>
              <select
                value={user.Position}
                onChange={(e) => setUser({ ...user, Position: e.target.value })}
              >
                {positionOptions.map((position, index) => (
                  <option key={index} value={position}>
                    {position}
                  </option>
                ))}
              </select>
              <label htmlFor="">Active</label>
              <input
                type="checkbox"
                checked={user.Active}
                onChange={(e) => setUser({ ...user, Active: e.target.checked })}
              />
              <br></br>
              {action === "Add" && (
                <button
                  style={{ marginLeft: "355px" }}
                  className="btn-p green-button decorated-button"
                  onClick={() => addUser()}
                >
                  Submit
                </button>
              )}
              {action === "Edit" && (
                <button
                  style={{ marginLeft: "355px" }}
                  className="btn-p green-button decorated-button"
                  onClick={() => {
                    updateUser();
                  }}
                >
                  Update
                </button>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Setting;
