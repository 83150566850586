import React from "react";
import { Form } from "react-bootstrap";
import HeroSection from "../Components/HeroSection.jsx";
import Footer from "../Components/Footer.jsx";

const Dashboard = () => {
  return (
    <div>
      <Form className="FromHome">
        <HeroSection />
        <Footer />
      </Form>
    </div>
  );
};

export default Dashboard;
