import React, { useState } from "react";
import nft from "./Videos/nft.jpg";
import "../pages/Style/NewCustomerModal.css";

const NewCustomerModal = ({ open, onClose, setEditClient, onSaveSuccess }) => {
  const [user, setUser] = useState({
    ID_Number_Passport: "",
    Full_Names: "",
    Surname: "",
    Branch_Name: "",
  });

  const [idFile, setIdFile] = useState(null);
  const [bankStatementFile, setBankStatementFile] = useState(null);

  const positionOptions = ["Please Select", "Jena Furse", "River Side"];

  const handleInputChange = (e) => {
    if (e.target.type === "file") {
      if (e.target.name === "IDFile") {
        setIdFile(e.target.files[0]);
      } else if (e.target.name === "BankStatementFile") {
        setBankStatementFile(e.target.files[0]);
      }
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      // Extract SignedInUser from the URL hash fragment
      const hashFragment = window.location.hash;
      const regex = /#Signed%20in%3A%20([^&]+)/;
      const match = hashFragment.match(regex);
      const SignedInUser = match ? decodeURIComponent(match[1]) : null;

      // Check if the SignedInUser is available
      if (!SignedInUser) {
        alert("User is not signed in. Please log in and try again.");
        return;
      }

      // Check if any of the required fields are empty
      const requiredFields = [
        "ID_Number_Passport",
        "Full_Names",
        "Surname",
        "Branch_Name",
      ];
      for (const field of requiredFields) {
        if (!user[field] || !user[field].trim()) {
          console.error(`${field} is required`);
          alert(`${field} is required`);
          return;
        }
      }

      const apiUrl = "http://localhost:8082/add-client";
      const checkUrl = "http://localhost:8082/check-client-exists";

      // Check if ID_Number_Passport already exists
      const checkResponse = await fetch(checkUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ID_Number_Passport: user.ID_Number_Passport }),
      });

      const checkData = await checkResponse.json();

      if (checkData.exists) {
        console.error("Client with the given ID already exists.");
        alert("Client with the given ID already exists.");
        return; // Stop the process if the ID already exists
      }

      // Continue with the client addition if the ID is unique
      const formData = new FormData();
      formData.append("ID_Number_Passport", user.ID_Number_Passport);
      formData.append("Full_Names", user.Full_Names);
      formData.append("Surname", user.Surname);
      formData.append("Branch_Name", user.Branch_Name);
      formData.append("Status", "New Customer");

      // Append additional fields to formData
      formData.append("Admin_Helped", SignedInUser); // Replace with actual value

      // Append files to formData only if they are selected
      if (idFile) {
        formData.append("ID_Copy", idFile);
      }

      if (bankStatementFile) {
        formData.append("Statement_Copy", bankStatementFile);
      }

      console.log([...formData.entries()]);

      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData, // No need to set Content-Type header; browser does it automatically
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log("Client added successfully:", responseData.insertedClient);
        alert("Client added successfully");
        onSaveSuccess(); // Assuming this function is defined
        setEditClient(null);
        onClose();
      } else {
        const errorData = await response.json();
        console.error("Error response from server:", errorData);
        alert("Failed to add client: " + (errorData.error || "Unknown error"));
      }
    } catch (error) {
      console.error("Error submitting client data:", error.message);
    }
  };

  if (!open) return null;

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer1"
      >
        <img src={nft} alt="/" />
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <h2 style={{ color: "black", marginBottom: "0%", margin: "6%" }}>
            New Client Information
          </h2>
          <div className="content">
            <label htmlFor="">Customer ID/Passport</label>
            <input
              type="text"
              name="ID_Number_Passport"
              placeholder="Enter ID/Passport"
              value={user.ID_Number_Passport}
              onChange={handleInputChange}
            />
            <label htmlFor="">Full Names</label>
            <input
              type="text"
              name="Full_Names"
              placeholder="Enter Full Names"
              value={user.Full_Names}
              onChange={handleInputChange}
            />
            <label htmlFor="">Surname</label>
            <input
              type="text"
              name="Surname"
              placeholder="Enter Surname"
              value={user.Surname}
              onChange={handleInputChange}
            />
            <div className="inputColumns">
              <div className="inputColumn">
                <label htmlFor="">Branch Name</label>
                <select
                  name="Branch_Name"
                  value={user.Branch_Name}
                  onChange={handleInputChange}
                >
                  {positionOptions.map((position, index) => (
                    <option key={index} value={position}>
                      {position}
                    </option>
                  ))}
                </select>
              </div>
              <div className="PDF-container">
                <div className="inputColumn">
                  <label htmlFor="">Upload ID (PDF)</label>
                  <hr />
                  <input
                    type="file"
                    name="IDFile"
                    className="PDF-input"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputColumn">
                  <label htmlFor="">Upload Bank Statement (PDF)</label>
                  <hr />
                  <input
                    type="file"
                    name="BankStatementFile"
                    className="PDF-input"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="btnContainer">
            <button
              style={{
                marginTop: "15%",
                marginLeft: "32px",
                marginBottom: "3%",
              }}
              className="btn-p green-button decorated-button"
              onClick={handleSubmit}
            >
              {"Create Profile"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCustomerModal;
