import React, { useState } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";
import "./Style/Analytics.css";

function Affordability() {
  const [customerData, setCustomerData] = useState(null);
  const [ID_Number_Passport, setID_Number_Passport] = useState("");
  const [outstandingAmount, setOutstandingAmount] = useState(0);

  // Function to calculate outstanding amount
  const calculateOutstanding = () => {
    // Existing code...
    if (!ID_Number_Passport) {
      window.alert("Please fill in the ID/Passport field.");
      return;
    }

    // Fetch customer data based on the entered ID/Passport
    fetch(`http://localhost:8082/${ID_Number_Passport}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Update state with the received customer data
        setCustomerData(data);

        // Continue with the calculation...
        const {
          primaryIncome,
          bonusIncome,
          otherIncome,
          incomeTax,
          uif,
          insurance,
          transport,
          food,
          medicalExpense,
          schoolFees,
          loanRepayments,
          otherMonthlyExpense,
        } = affordabilityData;

        // Perform the calculation
        const totalIncome =
          parseFloat(primaryIncome) +
          parseFloat(bonusIncome) +
          parseFloat(otherIncome);
        const totalDeductions =
          parseFloat(incomeTax) +
          parseFloat(uif) +
          parseFloat(insurance) +
          parseFloat(transport) +
          parseFloat(food) +
          parseFloat(medicalExpense) +
          parseFloat(schoolFees) +
          parseFloat(loanRepayments) +
          parseFloat(otherMonthlyExpense);

        const calculatedOutstandingAmount = totalIncome - totalDeductions;

        // Update state with the calculated outstanding amount
        setOutstandingAmount(calculatedOutstandingAmount);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setCustomerData(null);
        window.alert(
          "Please insert the correct ID or Passport. Please try again."
        );
      });
  };
  const [affordabilityData, setAffordabilityData] = useState({
    primaryIncome: 0,
    bonusIncome: 0,
    otherIncome: 0,
    incomeTax: 0,
    uif: 0,
    insurance: 0,
    transport: 0,
    food: 0,
    medicalExpense: 0,
    schoolFees: 0,
    loanRepayments: 0,
    otherMonthlyExpense: 0,
  });

  // Function to handle input changes in the affordability section
  const handleAffordabilityInputChange = (e, fieldName) => {
    const value = e.target.value;
    // Check if the value is numeric or not
    const numericValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    setAffordabilityData((prevData) => ({
      ...prevData,
      [fieldName]: numericValue,
    }));
  };
  const handleSaveAffordability = async () => {
    try {
      const fullName = customerData?.Full_Names || "-";
      const clientCode = customerData?.Client_Code || "-";
      const outstandingAffordability = outstandingAmount.toFixed(2);

      // Check if outstandingAffordability is zero
      if (outstandingAffordability === "0.00") {
        alert("Please calculate affordability before proceeding.");
        return; // Exit the function early
      }

      const userConfirmed = window.confirm(
        `Are you sure you want to save affordability?\n\nNames: ${fullName}\nClient Code: ${clientCode}\nOutstanding Affordability: R ${outstandingAffordability}`
      );

      if (userConfirmed) {
        const requestBody = {
          fullName,
          clientCode,
          outstandingAffordability,
        };

        const response = await fetch(
          "http://localhost:8082/add-affordability",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        alert("Affordability data saved successfully!");
        // Reset form or perform any additional actions
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      alert("Failed to save data. Please try again.");
    }
  };
  const divStyle = {
    backgroundColor: "#00ced1",
    border: "2px solid black",
    borderRadius: "3px",
    textAlign: "center",
    marginTop: "10px",
    width: "200px",
  };
  return (
    <div className="Affordability-container">
      <Card style={{ width: "29rem", height: "540px" }}>
        <Card.Body>
          <h2>Affordability Calculations</h2>
          <br></br>
          <hr />
          <div style={{ marginTop: "20px", marginBottom: "8px" }}>
            <Form.Label>ID Number/Passport :</Form.Label>
            <Form.Control
              className="InputID"
              type="InputAffordability"
              placeholder="Enter ID Number/Passport"
              value={ID_Number_Passport}
              onChange={(e) => setID_Number_Passport(e.target.value)}
            />
            <div style={divStyle}>Cross Income</div>
            <Form.Group
              className="mb-3"
              controlId="formHorizontalPrimaryIncome"
            >
              <Form.Label column sm={6}>
                Primary Income :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Primary Income"
                  value={affordabilityData.primaryIncome}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "primaryIncome")
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formHorizontalBonusIncome">
              <Form.Label column sm={6}>
                Bonus Income :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Bonus Income"
                  value={affordabilityData.bonusIncome}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "bonusIncome")
                  }
                />
              </Col>
              <Form.Label column sm={6}>
                Other Income :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Other Income"
                  value={affordabilityData.otherIncome}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "otherIncome")
                  }
                />
              </Col>
            </Form.Group>
            <div style={divStyle}>Less Statutory Deductions</div>
            <Form.Group className="mb-3" controlId="formHorizontalBonusIncome">
              <Form.Label column sm={6}>
                Income Tax :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter tax"
                  value={affordabilityData.incomeTax}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "incomeTax")
                  }
                />
              </Col>
              <Form.Label column sm={6}>
                UIF :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter UIF"
                  value={affordabilityData.uif}
                  onChange={(e) => handleAffordabilityInputChange(e, "uif")}
                />
              </Col>
              <Form.Label column sm={6}>
                Insurance :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Insurance"
                  value={affordabilityData.insurance}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "insurance")
                  }
                />
              </Col>
              <div
                className="Buttons"
                style={{ display: "flex", gap: "10px", marginTop: "10px" }}
              >
                <Button
                  as="input"
                  type="button"
                  value="Calculate Affordability"
                  className="BtnDecoration"
                  style={{ backgroundColor: "darkblue", color: "white" }}
                  onClick={calculateOutstanding}
                />
                <Button
                  as="input"
                  type="button"
                  value="Save"
                  className="BtnDecoration"
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    marginRight: "600px",
                  }}
                  onClick={handleSaveAffordability}
                ></Button>
              </div>
            </Form.Group>
          </div>

          <div className="LessLivingExpenses">
            <div style={divStyle}>Less living Expenses</div>
            <Form.Group className="mb-3" controlId="formHorizontalBonusIncome">
              <Form.Label column sm={6}>
                Transport :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Transport"
                  value={affordabilityData.transport}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "transport")
                  }
                />
              </Col>
              <Form.Label column sm={6}>
                Food :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Food "
                  value={affordabilityData.food}
                  onChange={(e) => handleAffordabilityInputChange(e, "food")}
                />
              </Col>
              <Form.Label column sm={6}>
                Medical Expe :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter  Medical Expe"
                  value={affordabilityData.medicalExpense}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "medicalExpense")
                  }
                />
              </Col>
              <Form.Label column sm={6}>
                School Fees :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter School Fees"
                  value={affordabilityData.schoolFees}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "schoolFees")
                  }
                />
              </Col>
              <Form.Label column sm={6}>
                Loan Repayments :
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Loan Repayments"
                  value={affordabilityData.loanRepayments}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "loanRepayments")
                  }
                />
              </Col>
              <Form.Label column sm={6}>
                Other Monthly Expense:
              </Form.Label>
              <Col sm={6}>
                <Form.Control
                  className="InputAffordability"
                  type="InputAffordability"
                  placeholder="Enter Monthly Expense"
                  value={affordabilityData.otherMonthlyExpense}
                  onChange={(e) =>
                    handleAffordabilityInputChange(e, "otherMonthlyExpense")
                  }
                />
              </Col>
              <div className="AffordabilityOutputs">
                <h5>Names : {customerData?.Full_Names || "-"}</h5>
                <h5>Client Code : {customerData?.Client_Code || "-"}</h5>
                <h5>Outstanding Affordability :</h5>
                <h5>R {outstandingAmount.toFixed(2)}</h5>
              </div>
            </Form.Group>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Affordability;
