import React, { useState, useEffect } from "react";
import "./Style/Profile.css";
import { PlusCircle } from "react-feather";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Profile = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [editableField, setEditableField] = useState({
    customerId: null,
    field: null,
    value: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "DD-MMM-YYYY";
    }

    return date
      .toLocaleDateString("en-GB", options)
      .toLowerCase()
      .replace(/\s/g, "-");
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch("http://localhost:8082/");
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    const sanitizedInput = input.replace(/[^0-9]/g, "");
    setSearchInput(sanitizedInput);
  };

  const handleEdit = (customerId, field, value) => {
    setEditableField({ customerId, field, value });
  };

  const handleSave = async (Customer_ID, field) => {
    try {
      const data = { [field]: parseFloat(editableField.value) };

      console.log("Sending data to update:", data);

      const response = await fetch(
        `http://localhost:8082/repayment-amount/${Customer_ID}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      console.log("Data saved successfully!");
      alert("Repayment amount updated successfully");

      fetchData();
    } catch (error) {
      console.error("Error saving data:", error.message);
    } finally {
      setEditableField({ customerId: null, field: null, value: null });
    }
  };

  const handleInputChangeEdit = (e) => {
    const value = e.target.value;
    setEditableField((prev) => ({ ...prev, value }));
  };

  const handleKeyDown = (e, customerId, field) => {
    if (e.key === "Enter") {
      handleSave(customerId, field);
    }
  };

  const isEditing = (customerId, field) => {
    return (
      editableField.customerId === customerId && editableField.field === field
    );
  };

  const filteredData = userData.filter((user) => {
    const idNumberPassport = String(user.ID_Number_Passport);
    return idNumberPassport.toLowerCase().includes(searchInput.toLowerCase());
  });

  const getMonthYearString = () => {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const exportToExcel = () => {
    const monthYear = getMonthYearString();
    const fileName = `UserProfiles-${monthYear}.xlsx`;
    let workbook;
    let worksheet;

    try {
      const existingWorkbook = XLSX.readFile(fileName, { type: "binary" });
      worksheet = existingWorkbook.Sheets[existingWorkbook.SheetNames[0]];
      const existingData = XLSX.utils.sheet_to_json(worksheet);

      userData.forEach((newUserData) => {
        const index = existingData.findIndex(
          (user) => user.Customer_ID === newUserData.Customer_ID
        );
        if (index !== -1) {
          existingData[index] = newUserData;
        } else {
          existingData.push(newUserData);
        }
      });

      workbook = existingWorkbook;
      worksheet = XLSX.utils.json_to_sheet(existingData, {
        header: [
          "Customer_ID",
          "ID_Number_Passport",
          "Full_Names",
          "Surname",
          "Loan_Amount",
          "Top_Up",
          "Repayment_Amount",
          "Payment_Date",
          "Admin_Helped",
        ],
      });
    } catch (e) {
      workbook = XLSX.utils.book_new();
      worksheet = XLSX.utils.json_to_sheet(userData, {
        header: [
          "Customer_ID",
          "ID_Number_Passport",
          "Full_Names",
          "Surname",
          "Loan_Amount",
          "Top_Up",
          "Repayment_Amount",
          "Payment_Date",
          "Admin_Helped",
        ],
      });
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, "Profiles");

    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <div className="user-container">
      <div className="title">Profile</div>
      <div>
        <div className="toolbar">
          <input
            type="number"
            className="search-box"
            placeholder="Search...using ID"
            value={searchInput}
            onInput={handleInputChange}
          />
          <button
            className="btn-p green-button decorated-button"
            type="button"
            onClick={exportToExcel}
          >
            <PlusCircle size={11} />
            <span>&nbsp;Save to Backup</span>
          </button>
        </div>
        <br />
        <div className="table-container">
          <table
            className="table"
            style={{ marginLeft: "10px", marginBottom: "10px" }}
          >
            <thead>
              <tr>
                <th>ID_Number_Passport</th>
                <th>Full_Names</th>
                <th>Surname</th>
                <th>Loan_Amount</th>
                <th>Top_Up</th>
                <th>
                  Repayment_Amount
                  <br />
                  (Double-click to edit)
                </th>
                <th>Payment_Date</th>
                <th>Admin_Helped</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="8">
                    <div className="loading-container">
                      <div className="spinner"></div>
                      <span>Loading...</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredData.map((user) => (
                  <tr key={user.Customer_ID}>
                    <td>{user.ID_Number_Passport}</td>
                    <td>{user.Full_Names}</td>
                    <td>{user.Surname}</td>
                    <td>R {user.Loan_Amount || "0.00"}</td>
                    <td>R {user.Top_Up || "0.00"}</td>
                    <td
                      onDoubleClick={() =>
                        handleEdit(
                          user.Customer_ID,
                          "Repayment_Amount",
                          user.Repayment_Amount
                        )
                      }
                    >
                      R{" "}
                      {isEditing(user.Customer_ID, "Repayment_Amount") ? (
                        <input
                          type="number"
                          value={editableField.value}
                          className="edit-box"
                          onChange={handleInputChangeEdit}
                          onKeyDown={(e) =>
                            handleKeyDown(
                              e,
                              user.Customer_ID,
                              "Repayment_Amount"
                            )
                          }
                        />
                      ) : (
                        user.Repayment_Amount || "0.00"
                      )}
                    </td>
                    <td>
                      {user.Payment_Date
                        ? formatDate(new Date(user.Payment_Date))
                        : "dd-mm-yyyy"}
                    </td>
                    <td>{user.Admin_Helped}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Profile;
