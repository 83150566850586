import React from "react";
import "./Style/Messages.css";

const ProfileSettings = () => {
  return (
    <div className="massage-container">
      <div className="title">Profile Settings</div>
    </div>
  );
};

export default ProfileSettings;
