import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import "./Style/Analytics.css";

function SearchCustomer() {
  const [customerSearchData, setcustomerSearchData] = useState(null);
  const [ID_Number_Passport, setID_Number_Passport] = useState("");

  const handleIdNumberChange = (event) => {
    setID_Number_Passport(event.target.value);
  };

  const searchCustomer = () => {
    if (!ID_Number_Passport) {
      window.alert("Please fill in the ID/Passport field.");
      return;
    }

    // Make a GET request to your backend API with the entered ID/Passport
    fetch(`http://localhost:8082/${ID_Number_Passport}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setcustomerSearchData(data);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setcustomerSearchData(null);
        window.alert(
          "Please insert the correct ID or Passport. Please try again."
        );
      });
  };
  return (
    <div className="search-container">
      <Card style={{ width: "22rem", height: "26rem" }}>
        <Card.Body>
          <h2>Search Customer</h2>

          <Card.Img
            variant="top"
            style={{ width: "147px", height: "118px", borderRadius: "3px" }}
            src={
              "https://www.shutterstock.com/image-vector/silhouette-vector-icon-upper-body-600w-2298564235.jpg"
            }
            onError={(e) => {
              console.error("Error loading image:", e.target.src);
              e.target.src =
                "https://www.shutterstock.com/image-vector/silhouette-vector-icon-upper-body-600w-2298564235.jpg";
            }}
          />
          <hr />
          <Form.Group className="SearchLabel" controlId="formGroupEmail">
            <Form.Label>ID/Passport :</Form.Label>
            <Form.Control
              className="InputSearch"
              type="text"
              placeholder="Enter ID/Passport"
              value={ID_Number_Passport}
              onChange={handleIdNumberChange}
            />
          </Form.Group>
          <h5>
            Names : {customerSearchData?.Full_Names}{" "}
            {customerSearchData?.Surname || "-"}
          </h5>
          <h5>Client Code : {customerSearchData?.Client_Code || "-"}</h5>
          <h5>
            Outstanding Amount : R {""}
            {customerSearchData?.Repayment_Amount || "0.00"}
          </h5>
          <h5>
            Nu Pay Card Num : {customerSearchData?.Nu_Pay_Card_Num || "-"}
          </h5>
          <h5>
            Payment Date :{" "}
            {customerSearchData?.Payment_Date
              ? new Date(customerSearchData.Payment_Date)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                  .replace(/ /g, "-")
                  .replace(
                    /([a-z])\w+/i,
                    (match) => match.charAt(0).toUpperCase() + match.slice(1)
                  )
              : "-"}
          </h5>
          <h5>Status : {customerSearchData?.Status || "-"}</h5>
        </Card.Body>
        <hr />
        <Button
          as="input"
          type="button"
          value="Search"
          className="BtnDecoration"
          style={{ backgroundColor: "darkgreen", color: "white" }}
          onClick={searchCustomer}
        ></Button>
      </Card>
    </div>
  );
}

export default SearchCustomer;
