import React, { useState, useEffect } from "react";
import "./Style/Calculate.css";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Affordability from "./Affordability.jsx";
import SearchCustomer from "./SearchCustomer.jsx";
import NewCustomerModal from "../Components/NewCustomerModal.jsx";
import CreateFullProfileModal from "../Components/CreateFullProfileModal.jsx";
import EditFullProfileModal from "../Components/EditFullProfileModal.jsx";
import PrintContractButton from "./Contract.jsx";
import { handleFormSubmit } from "../Components/Calculations.jsx";

const Calculate = ({ role }) => {
  const [paymentType, setPaymentType] = useState("Week");
  const [Payment_Date, setPaymentDate] = useState("");
  const [Loan_Amount, setLoanAmount] = useState("");
  const [Repayment_Amount, setRepayment_Amount] = useState(0);
  const [isNewCustomerModalOpen, setNewCustomerModalOpen] = useState(false);
  const [isCreateProfileModalOpen, setCreateProfileModalOpen] = useState(false);
  const [isEditProfileModalOpen, setEditProfileModalOpen] = useState(false);

  const [customerLoanData, setCustomerLoanData] = useState(null);
  const [ID_Number_Passport, setID_Number_Passport] = useState("");

  const [adminFee, setAdminFee] = useState(0);
  const [interestFee, setInterestFee] = useState(0);
  const [monthlyFee, setMonthlyFee] = useState(0);

  const { Full_Names, Surname } = customerLoanData || {};

  const lenderName = Full_Names && Surname ? `${Full_Names} ${Surname}` : "";
  const borrowerName = "John Doe";
  const loanAmount = Loan_Amount || "0.00";
  const interestRate = "5";
  const numberOfInstallments = paymentType === "Week" ? "Week" : "Month";
  const firstRepaymentDate = Payment_Date || "-";
  const [Loan_Purpose, setLoan_Purpose] = useState("");

  const handleIdNumberChange = (event) => {
    setID_Number_Passport(event.target.value);
  };

  const handleEditSaveSuccess = () => {
    // Implement the logic for handling the success of saving edits to the profile
    console.log("Edit saved successfully");
    // You can add any other actions you want to perform after a successful save here
  };
  const handleEditClient = () => {
    // Implement logic to update client state
    console.log("Client edited");
  };

  // Fetch interest rates from the backend
  useEffect(() => {
    fetch("http://localhost:8083/")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          const firstInterestRate = data[0];
          setAdminFee(firstInterestRate.Admin_Fee);
          setInterestFee(firstInterestRate.Interest_Fee);
          setMonthlyFee(firstInterestRate.Monthly_Fee);
        } else {
          console.warn("No interest rates found in the array.");
        }
      })
      .catch((error) => {
        console.error("Error fetching interest rates:", error);
      });
  }, []);

  const openNewCustomerModal = () => {
    setNewCustomerModalOpen(true);
  };

  const closeNewCustomerModal = () => {
    setNewCustomerModalOpen(false);
  };

  const openCreateProfileModal = () => {
    setCreateProfileModalOpen(true);
  };

  const closeCreateProfileModal = () => {
    setCreateProfileModalOpen(false);
  };

  const openEditProfileModal = () => {
    setEditProfileModalOpen(true);
  };

  const closeEditProfileModal = () => {
    setEditProfileModalOpen(false);
  };

  const Calculate = (e) => {
    handleFormSubmit(
      e,
      ID_Number_Passport,
      Loan_Amount,
      Payment_Date,
      paymentType,
      interestFee,
      monthlyFee,
      adminFee,
      setCustomerLoanData,
      setRepayment_Amount
    );
  };

  const handlePaymentDateChange = (e) => {
    // Update the paymentDate state when the input value changes
    setPaymentDate(e.target.value);
  };

  const Nu_pay_link = () => {
    window.location.href = "https://www.nupayments.co.za/home";
  };

  const handleOrderLoan = async () => {
    try {
      // Extract the hash fragment from the URL
      const hashFragment = window.location.hash;
      console.log("Hash fragment:", hashFragment); // Log the hash fragment

      // Regular expression to extract the signed-in user information
      const regex = /#Signed%20in%3A%20([^&]+)/;
      const match = hashFragment.match(regex);
      console.log("Matched result:", match);

      // Decode the signed-in user information from the hash
      const signedInUserFromHash = match ? decodeURIComponent(match[1]) : null;
      console.log("Signed in user from hash:", signedInUserFromHash);

      if (!signedInUserFromHash) {
        console.error("No signed-in user found in the hash fragment");
        alert("User is not signed in. Please log in and try again.");
        return;
      }

      // Ensure essential information is available
      if (
        !ID_Number_Passport ||
        !Loan_Amount ||
        !Payment_Date ||
        isNaN(Repayment_Amount)
      ) {
        alert("Please provide all required information before ordering a Loan");
        return;
      }

      // Check if the comment field is empty
      const comment = document.getElementById(
        "exampleForm.ControlTextarea1"
      ).value;
      if (!comment.trim()) {
        alert("Please provide a comment before ordering a Loan");
        return;
      }

      // Fetch the current Repayment_Amount from the database
      const response = await fetch(
        `http://localhost:8082/repayment-amount/${ID_Number_Passport}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch repayment amount");
      }

      const data = await response.json();
      const currentRepaymentAmount = data.Repayment_Amount;

      // Check if the fetched Repayment_Amount is less than 150
      if (currentRepaymentAmount >= 150) {
        alert(
          "Sorry, the repayment amount should be less than R150. Please adjust the repayment amount and try again."
        );
        return;
      }

      // Display a confirmation alert with loan details
      const userConfirmed = window.confirm(
        `Signed in: ${signedInUserFromHash}\n\nAre you sure you want to order the Loan?\n\nLoan Amount: R ${Loan_Amount}\nPayment Date: ${new Date(
          Payment_Date
        )
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
          .replace(/ /g, "-")
          .replace(
            /([a-z])\w+/i,
            (match) => match.charAt(0).toUpperCase() + match.slice(1)
          )}\nPayment Amount: R ${Repayment_Amount.toFixed(
          2
        )}\nComment: ${comment}`
      );

      if (userConfirmed) {
        const requestBody = {
          Loan_Amount,
          Payment_Date,
          Repayment_Amount,
          comment,
          ID_Number_Passport,
          Status: "Processing Loan",
          SignedInUser: signedInUserFromHash, // Add the signed-in user information from the hash to the requestBody
        };

        const response = await fetch("http://localhost:8082/order-loan", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        alert("Loan ordered and saved successfully!");
        // Reset form or perform any additional actions
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      alert("Failed to save data. Please try again.");
    }
  };

  const handleOrderTopUp = async () => {
    try {
      // Extract the signed-in user from the URL hash fragment
      const hashFragment = window.location.hash;
      const regex = /#Signed%20in%3A%20([^&]+)/;
      const match = hashFragment.match(regex);
      const SignedInUser = match ? decodeURIComponent(match[1]) : null;

      if (!SignedInUser) {
        alert("User is not signed in. Please log in and try again.");
        return;
      }

      // Find the element containing the loan amount
      let loanAmountElement = null;
      const h6Elements = document.querySelectorAll("h5");
      h6Elements.forEach((element) => {
        if (element.textContent.includes("Loan Amount")) {
          loanAmountElement = element;
        }
      });

      if (!loanAmountElement) {
        alert("Failed to find the loan amount element");
        return;
      }

      // Extract loan amount from the element's text content
      const loanAmountText = loanAmountElement.textContent.trim();
      const Top_Up = parseFloat(
        loanAmountText
          .split(":")[1]
          .trim()
          .replace(/[^\d.]/g, "")
      );

      // Check if essential information is available for top-up
      if (
        !ID_Number_Passport ||
        !Top_Up ||
        !Payment_Date ||
        isNaN(Repayment_Amount)
      ) {
        alert(
          "Please provide all required information before ordering a Top Up"
        );
        return;
      }

      // Fetch the Admin_Helped value from the database
      const response = await fetch(
        `http://localhost:8082/get-admin-helped/${ID_Number_Passport}`,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Admin_Helped data");
      }
      const data = await response.json();
      const { Admin_Helped } = data;

      // Check if the logged-in user matches Admin_Helped
      if (Admin_Helped !== SignedInUser) {
        alert(
          `This action cannot be performed as you do not have the required permissions.\n\nAdmin who can help: ${Admin_Helped}\n\nPlease contact the mentioned admin to proceed with the top-up order.`
        );
        return;
      }

      // Check if the comment field is empty
      const comment = document.getElementById(
        "exampleForm.ControlTextarea1"
      ).value;
      if (!comment.trim()) {
        alert("Please provide a comment before ordering a Top Up");
        return;
      }

      // Display a confirmation alert
      const userConfirmed = window.confirm(
        `Are you sure you want to order the Top Up?\n\nTop Up Amount: R ${Top_Up}\nPayment Date: ${new Date(
          Payment_Date
        )
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
          .replace(/ /g, "-")
          .replace(
            /([a-z])\w+/i,
            (match) => match.charAt(0).toUpperCase() + match.slice(1)
          )}\nPayment Amount: R ${Repayment_Amount.toFixed(
          2
        )}\nComment: ${comment}`
      );

      if (userConfirmed) {
        const requestBody = {
          Top_Up,
          Payment_Date,
          Repayment_Amount,
          comment,
          ID_Number_Passport,
          Status: "Processing Top Up",
          Admin_Helped, // Include Admin_Helped in the request body
        };

        const response = await fetch("http://localhost:8082/order-top-up", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        alert("Top Up ordered and saved successfully!");
        // Reset form or perform any additional actions
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      alert("Failed to save data. Please try again.");
    }
  };

  const loanPurposeOptions = [
    "Please Select",
    "Credit",
    "Business",
    "Transport",
    "Education",
    "Medical",
    "Home Improvement",
    "Other",
  ];

  const handleLoanPurposeChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected value:", selectedValue); // Log selected value for debugging

    // Update Loan_Purpose with the selected value
    setLoan_Purpose(selectedValue);

    // Check if the selected value is "Please Select"
    if (selectedValue === "Please Select") {
      // Show an alert
      window.alert("Please select a valid loan purpose.");
      console.log("Alert shown for invalid loan purpose.");
    }
  };

  return (
    <div className="calculates-container">
      <div className="title">Calculate</div>
      <div className="LoanCalculation">
        <Card
          className="Card"
          style={{
            width: "25rem",
            height: "35rem",
            padding: "10px",
            backgroundColor: "rgba(223, 220, 220, 0.4)",
          }}
        >
          <h2>Loan Calculations</h2>
          <br></br>
          <hr />
          <Form.Group className="InputContainer">
            <Form.Label>ID/Passport :</Form.Label>
            <Form.Control
              className="Input"
              type="text"
              value={ID_Number_Passport}
              placeholder="Enter ID Number/Passport"
              onChange={handleIdNumberChange}
            />
          </Form.Group>
          <Form.Group className="RadioButtons">
            <Form.Label>Loan Amount :</Form.Label>
            <Form.Control
              className="InputLoan"
              type="LoanAmount"
              placeholder="Enter Loan Amount"
              value={Loan_Amount}
              onChange={(e) => {
                const inputValue = e.target.value;
                setLoanAmount(inputValue);

                if (!/^\d+(\.\d{2})?$/.test(inputValue)) {
                  e.target.setCustomValidity(
                    "The price must be in the format xx.xx"
                  );
                } else {
                  e.target.setCustomValidity("");
                }
              }}
            />
            <Form.Label>Payment Date :</Form.Label>
            <Form.Control
              type="date"
              id="PaymentDate"
              className="Date"
              name="PaymentDate"
              onChange={handlePaymentDateChange}
            />
          </Form.Group>
          <div className="LoanOptions">
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="mb-3">
                <Form.Check
                  inline
                  label="Weeks"
                  name="group1"
                  type={type}
                  id={`inline-${type}-1`}
                  checked={paymentType === "Week"}
                  onChange={() => setPaymentType("Week")}
                />
                <Form.Check
                  inline
                  label="Month"
                  name="group1"
                  type={type}
                  id={`inline-${type}-2`}
                  checked={paymentType === "Month"}
                  onChange={() => setPaymentType("Month")}
                />
              </div>
            ))}
            <div className="LoanPurposeSelect">
              <label htmlFor="">Loan Purpose</label>
              <select
                name="Loan_Purpose"
                id="loanPurpose"
                value={Loan_Purpose}
                onChange={handleLoanPurposeChange}
                style={{ width: "50%", height: "10%" }}
                className="LoanPurposeSelect__select"
              >
                {loanPurposeOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <hr />

          <div className="Buttons">
            <Button
              as="input"
              type="button"
              value="Calculate"
              className="BtnDecoration"
              style={{ backgroundColor: "darkblue", color: "white" }}
              onClick={Calculate}
            />{" "}
            <PrintContractButton
              lenderName={lenderName}
              borrowerName={borrowerName}
              loanAmount={loanAmount}
              interestRate={interestRate}
              numberOfInstallments={numberOfInstallments}
              firstRepaymentDate={firstRepaymentDate}
              purpose={Loan_Purpose}
            />
            <Button
              variant="link"
              className="BtnDecoration"
              style={{
                color: "darkpurple",
                backgroundColor: "rgba(255, 255, 255, 0.6)",
              }}
              onClick={Nu_pay_link}
            >
              Nu_pay
            </Button>
          </div>

          <Card.Body>
            <hr />
            <Card.Title style={{ marginTop: "10px" }}>
              Client Information
            </Card.Title>
            <hr />
            <div style={{ marginBottom: "10px", marginTop: "7px" }}>
              <h5>
                Names : {customerLoanData?.Full_Names}{" "}
                {customerLoanData?.Surname || "-"}
              </h5>
              <h5>Client Code : {customerLoanData?.Client_Code || "-"}</h5>
              <h5>Loan Amount : R {Loan_Amount || "0.00"}</h5>
              <h5>
                Payment Date : {""}
                {Payment_Date
                  ? new Date(Payment_Date)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                      .replace(/ /g, "-")
                      .replace(
                        /([a-z])\w+/i,
                        (match) =>
                          match.charAt(0).toUpperCase() + match.slice(1)
                      )
                  : "-"}
              </h5>
              <h5>
                Payment Amount : R {Repayment_Amount.toFixed(2) || "0.00"}
              </h5>
              <hr />
              <h6>Comment</h6>
            </div>
            <Form.Group
              className="Comment"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                className="Comment"
                style={{ width: "370px" }}
              />
            </Form.Group>
            <hr />
            <Button
              as="input"
              type="button"
              value="Order Loan"
              className="BtnDecoration"
              style={{ backgroundColor: "darkgreen", color: "white" }}
              onClick={handleOrderLoan}
            />{" "}
            <Button
              as="input"
              type="submit"
              className="BtnDecoration"
              value="Order Top_Up"
              style={{ backgroundColor: "darkblue", color: "white" }}
              onClick={handleOrderTopUp}
            ></Button>
          </Card.Body>
        </Card>
      </div>

      <SearchCustomer />

      <div className="Button-container">
        <Card style={{ width: "22rem", height: "5rem" }}>
          <Card.Body>
            <h2>Creating Buttons</h2>
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              <Button
                as="input"
                type="button"
                value="New Customer"
                className="BtnDecoration"
                style={{ backgroundColor: "darkgreen", color: "white" }}
                onClick={openNewCustomerModal}
              />
              <Button
                as="input"
                type="button"
                value="Create full Profile"
                className="BtnDecoration"
                style={{ backgroundColor: "darkblue", color: "white" }}
                onClick={openCreateProfileModal}
              />
              {/* Conditionally render the button based on the user's role */}
              {role !== "Admin" && (
                <button
                  type="button"
                  className="BtnDecoration"
                  style={{ backgroundColor: "darkred", color: "white" }}
                  onClick={openEditProfileModal}
                >
                  Edit
                </button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>

      <Affordability />

      <NewCustomerModal
        open={isNewCustomerModalOpen}
        onClose={closeNewCustomerModal}
        setEditClient={handleEditClient}
        onSaveSuccess={handleEditSaveSuccess}
      />
      <CreateFullProfileModal
        open={isCreateProfileModalOpen}
        onClose={closeCreateProfileModal}
        setEditClient={handleEditClient}
        onSaveSuccess={handleEditSaveSuccess}
      />
      <EditFullProfileModal
        open={isEditProfileModalOpen}
        onClose={closeEditProfileModal}
        setEditClient={handleEditClient}
        onSaveSuccess={handleEditSaveSuccess}
      />
    </div>
  );
};

export default Calculate;
