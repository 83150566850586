import React from "react";
import "./Style/Messages.css";

const Notifications = () => {
  return (
    <div className="massage-container">
      <div className="title">Notifications Settings</div>
    </div>
  );
};

export default Notifications;
