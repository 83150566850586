// Import React, useState, useEffect
import React, { useState, useEffect } from "react";
import nft from "./Videos/nft.jpg";
import "../pages/Style/EditModal.css";

const ViewEditModal = ({
  open,
  onClose,
  editClient,
  setEditClient,
  onSaveSuccess,
}) => {
  const [user, setUser] = useState({
    Client_Code: "",
    Nu_Pay_Card_Num: "",
    Comment: "",
    Status: "Please Select",
  });

  useEffect(() => {
    if (editClient) {
      setUser({
        Client_Code: editClient.Client_Code,
        Nu_Pay_Card_Num: editClient.Nu_Pay_Card_Num,
        Comment: editClient.Comment,
        Status: editClient.Status,
      });
    }
  }, [editClient]);

  const positionOptions = [
    "Please Select",
    "Create Full Profile",
    "Loan Not Approved",
    "Top Up Not Approved",
    "Loan Approved",
    "Top Up Approved",
  ];

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const requiredFields = [
        "Client_Code",
        "Nu_Pay_Card_Num",
        "Comment",
        "Status",
      ];
      for (const field of requiredFields) {
        if (
          user[field] === undefined ||
          user[field] === null ||
          user[field].trim() === ""
        ) {
          console.error(`${field} is required`);
          alert(`${field} is required`);
          return;
        }
      }

      let apiUrl = "";
      let method = "";

      if (editClient && editClient.Customer_ID) {
        apiUrl = `http://localhost:8082/customer/${editClient.Customer_ID}`;
        method = "PUT";
      } else {
        apiUrl = "http://localhost:8082/add-client";
        method = "POST";
      }

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        console.log(
          editClient
            ? "Client updated successfully"
            : "Client added successfully"
        );
        alert("Client updated successfully");

        onSaveSuccess();
        setEditClient(null);
        onClose();
      } else {
        console.error("Error submitting client data:", response.statusText);
        alert("Error submitting client data:", response.statusText); // Add an alert for error
      }
    } catch (error) {
      console.error("Error submitting client data:", error.message);
      alert("Error submitting client data:", error.message); // Add an alert for error
    }
  };

  if (!open) return null;

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainerEdit"
      >
        <img src={nft} alt="/" />
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <h2 style={{ color: "black", marginBottom: "0%", margin: "9%" }}>
            Client Information
          </h2>
          <div className="content">
            <label htmlFor="">Client Code</label>
            <input
              type="text"
              name="Client_Code"
              placeholder={"Enter Client Code"}
              value={user.Client_Code}
              onChange={handleInputChange}
            />
            <label htmlFor="">Nu_Pay_Card Num</label>
            <input
              type="text"
              name="Nu_Pay_Card_Num"
              placeholder={"Enter Nu_Pay_Card Num"}
              value={user.Nu_Pay_Card_Num}
              onChange={handleInputChange}
            />
            <label htmlFor="">Status</label>
            <select
              name="Status"
              value={user.Status}
              onChange={handleInputChange}
            >
              {positionOptions.map(
                (
                  position // Use position as key
                ) => (
                  <option key={position} value={position}>
                    {position}
                  </option>
                )
              )}
            </select>
            {/* Comment Column */}
            <label htmlFor="">Comment</label>
            <input
              type="text"
              name="Comment"
              className="Comment"
              value={user.Comment}
              style={{ height: "90px" }}
              onChange={handleInputChange}
            />
          </div>

          <div className="btnContainer">
            <button
              style={{ marginLeft: "32px", marginBottom: "3%" }}
              className="btn-p green-button decorated-button"
              onClick={handleSubmit}
            >
              {editClient ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEditModal;
