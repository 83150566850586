import React from "react";
import "./HeroSection.css";
import Video from "./Videos/video-3.mp4";

function HeroSection() {
  return (
    <div className="hero-container">
      <video src={Video} autoPlay loop muted />
      <h1>Loan Management</h1>
      <p>Efficiently Managing Loans for a Brighter Financial Future</p>
    </div>
  );
}

export default HeroSection;
