import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import nft from "./Videos/nft.jpg";
import "../pages/Style/CreateFullProfileModal.css";

const EditFullProfileModal = ({
  open,
  onClose,
  setEditClient,
  onSaveSuccess,
}) => {
  // Define the initial state for the user
  const initialUserState = {
    ID_Number_Passport: "",
    Full_Names: "",
    Surname: "",
    Status: "",
    Contact_Number: "",
    Other_Contact_Num: "",
    Email_Address: "",
    Home_Address: "",
    Next_Of_kid_Names_Sur: "",
    Next_Of_kid_Contact: "",
    Employer_Names_Surname: "",
    Employer_Contact: "",
    Bank_Name: "",
    Bank_Type: "",
  };

  const [user, setUser] = useState(initialUserState);

  const BankTypeOptions = ["Please Select", "Savings", "Credit", "Business"];

  const handleSearchClick = async () => {
    try {
      const response1 = await fetch(
        `http://localhost:8082/${user.ID_Number_Passport}`
      );
      if (!response1.ok) {
        throw new Error(`HTTP error! Status: ${response1.status}`);
      }
      const userData = await response1.json();

      const response2 = await fetch(
        `http://localhost:8084/customer_information`
      );
      if (!response2.ok) {
        throw new Error(`HTTP error! Status: ${response2.status}`);
      }
      const customerData = await response2.json();

      // Find the user data based on "Customer_ID" from customerData array
      const foundUser = customerData.find(
        (data) => data.Customer_ID === userData.Customer_ID
      );

      // Merge foundUser with userData
      const mergedData = { ...userData, ...foundUser };

      // Include Bank Type data
      mergedData.Bank_Type = user.Bank_Type;

      setUser(mergedData);
    } catch (error) {
      console.error("Error fetching customer data:", error.message);
      window.alert(
        "Please insert the correct ID or Passport. Please try again."
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    console.log(`Updating field ${name} with value: "${value}"`); // Debugging log

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      console.log("Current user state before submission:", user); // Debugging log

      // List of required fields for validation
      const requiredFields = [
        "ID_Number_Passport",
        "Contact_Number",
        "Other_Contact_Num",
        "Email_Address",
        "Home_Address",
        "Next_Of_kid_Names_Sur",
        "Next_Of_kid_Contact",
        "Employer_Names_Surname",
        "Employer_Contact",
        "Bank_Name",
        "Bank_Type",
      ];

      // Trim all string fields in the user object
      const trimmedUser = { ...user };
      Object.keys(trimmedUser).forEach((key) => {
        if (typeof trimmedUser[key] === "string") {
          trimmedUser[key] = trimmedUser[key].trim();
        }
      });

      // Validate required fields
      for (const field of requiredFields) {
        if (!trimmedUser[field]) {
          console.error(`${field} is required`);
          alert(`${field} is required`);
          return; // Exit the function if a required field is missing
        }
      }

      // Define the API URL
      const apiUrl = `http://localhost:8084/update-full-profile/${trimmedUser.Customer_ID}`;

      // Make the API request
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include credentials like cookies in the request
        body: JSON.stringify(trimmedUser),
      });

      // Handle the response
      if (response.ok) {
        console.log("Client profile updated successfully");
        alert("Client profile updated successfully");

        onSaveSuccess(); // Call success handler
        setEditClient(null); // Clear the editing state
        onClose(); // Close the modal
      } else {
        console.error("Error updating client profile:", response.statusText);
        alert(`Error updating client profile: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error updating client profile:", error.message);
      alert("Error updating client profile");
    }
  };

  const handleModalClose = () => {
    setUser(initialUserState); // Reset user state
    onClose(); // Close the modal
  };

  if (!open) return null;

  return (
    <div onClick={handleModalClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainerCreate"
      >
        <img src={nft} alt="/" />
        <div className="modalRight">
          <p className="closeBtn" onClick={handleModalClose}>
            X
          </p>
          <h2 style={{ color: "black", marginBottom: "0%", margin: "4%" }}>
            Edit Full Profile
          </h2>
          <div className="content">
            <h5>Search with ID/Passport</h5>
            <div className="inputColumns">
              <div className="inputColumn">
                <label htmlFor="">Customer ID/Passport</label>
                <input
                  type="text"
                  name="ID_Number_Passport"
                  placeholder={"Enter ID/Passport"}
                  value={user.ID_Number_Passport}
                  onChange={handleInputChange}
                />
              </div>
              <Button
                as="input"
                type="button"
                value="Search"
                className="BtnDecoration"
                style={{ backgroundColor: "darkgreen", color: "white" }}
                onClick={handleSearchClick}
              ></Button>
              <div className="inputColumn">
                <label htmlFor="">Full Names</label>
                <input
                  type="text"
                  name="Full_Names"
                  placeholder={"Full Names"}
                  value={user.Full_Names}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Surname</label>
                <input
                  type="text"
                  name="Surname"
                  placeholder={"Surname"}
                  value={user.Surname}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="inputColumns">
              <div className="inputColumn">
                <label htmlFor="">Status</label>
                <input
                  type="text"
                  name="Status"
                  placeholder={"Status"}
                  value={user.Status}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="inputColumns2">
              <div className="inputColumn">
                <label htmlFor="">Contact Number</label>
                <input
                  type="text"
                  name="Contact_Number"
                  placeholder={"Enter Contact Number"}
                  value={user.Contact_Number}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Other Contact Num</label>
                <input
                  type="text"
                  name="Other_Contact_Num"
                  placeholder={"Enter Other Contact Num"}
                  value={user.Other_Contact_Num}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Email Address</label>
                <input
                  type="text"
                  name="Email_Address"
                  placeholder={"Enter Email Address"}
                  value={user.Email_Address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Home Address</label>
                <input
                  type="text"
                  name="Home_Address"
                  placeholder={"Enter Home Address"}
                  value={user.Home_Address}
                  onChange={handleInputChange}
                />
              </div>

              <div className="inputColumn">
                <label htmlFor="">Next Of Kid Names & Sur</label>
                <input
                  type="text"
                  name="Next_Of_kid_Names_Sur"
                  placeholder={"Enter Next Of Kid Names & Surname"}
                  value={user.Next_Of_kid_Names_Sur}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="inputColumns3">
              <div className="inputColumn">
                <label htmlFor="">Next Of Kid Contact</label>
                <input
                  type="text"
                  name="Next_Of_kid_Contact"
                  placeholder={"Next Of Kid Contact"}
                  value={user.Next_Of_kid_Contact}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Employer Names & Surname</label>
                <input
                  type="text"
                  name="Employer_Names_Surname"
                  placeholder={"Enter Employer Names & Surname"}
                  value={user.Employer_Names_Surname}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Employer Contact</label>
                <input
                  type="text"
                  name="Employer_Contact"
                  placeholder={"Enter Employer Contact"}
                  value={user.Employer_Contact}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Bank Name</label>
                <input
                  type="text"
                  name="Bank_Name"
                  placeholder={"Enter Bank Name"}
                  value={user.Bank_Name}
                  onChange={handleInputChange}
                />
              </div>

              <div className="inputColumn">
                <label htmlFor="">Bank Type</label>
                <select
                  name="Bank_Type"
                  value={user.Bank_Type}
                  onChange={handleInputChange}
                >
                  {BankTypeOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="btnContainer">
            <button
              style={{
                marginLeft: "32px",
                marginBottom: "3%",
              }}
              className="btn-p green-button decorated-button"
              onClick={handleSubmit}
            >
              Edit Full Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFullProfileModal;
