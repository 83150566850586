import React, { useState } from "react";
import nft from "./Videos/nft.jpg";
import "../pages/Style/SigninModal.css";

const SinginModal = ({
  open,
  onClose,
  editClient,
  setEditClient,
  onSaveSuccess,
}) => {
  const [user, setUser] = useState({
    Email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const apiUrl = "http://localhost:8085/update-password";

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.Email,
          newPassword: user.password,
        }),
      });

      if (response.ok) {
        console.log("Password updated successfully");
        alert("Password updated successfully");

        // Reset form fields
        setUser({
          Email: "",
          password: "",
        });

        onClose();
      } else if (response.status === 404) {
        const data = await response.json();
        alert(data.error);
      } else if (response.status === 400) {
        alert("You cannot update your password because it's already set.");
      } else {
        const errorMessage = await response.text();
        console.error("Error updating password:", errorMessage);
        alert("An error occurred while updating password. Please try again.");
      }
    } catch (error) {
      console.error("Error updating password:", error.message);
      alert("An error occurred while updating password. Please try again.");
    }
  };

  if (!open) return null;

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainerEdit"
      >
        <img src={nft} alt="/" />
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <h2 style={{ color: "black", marginBottom: "-10px", margin: "5%" }}>
            Sign in
          </h2>
          <div className="content">
            <label style={{ marginLeft: "-85px" }} htmlFor="">
              Email
            </label>
            <input
              type="text"
              name="Email"
              placeholder={"Enter Email"}
              value={user.Email}
              onChange={handleInputChange}
            />
            <label style={{ marginLeft: "-45px" }} htmlFor="">
              New password
            </label>
            <input
              type="password"
              name="password"
              placeholder={"Enter new password"}
              value={user.password}
              onChange={handleInputChange}
            />
          </div>

          <div className="btnContainer">
            <button
              style={{ marginLeft: "-15px", marginBottom: "6%" }}
              className="btn-p green-button decorated-button"
              onClick={handleSubmit}
            >
              Update Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinginModal;
