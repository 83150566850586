import React, { useState, useEffect } from "react";
import nft from "./Videos/nft.jpg";
import "../pages/Style/CreateFullProfileModal.css";
import Button from "react-bootstrap/Button";

const CreateFullProfileModal = ({
  open,
  onClose,
  setEditClient,
  onSaveSuccess,
}) => {
  const [user, setUser] = useState({
    ID_Number_Passport: "",
    Contact_Number: "",
    Other_Contact_Num: "",
    Email_Address: "",
    Home_Address: "",
    Next_Of_kid_Names_Sur: "",
    Next_Of_kid_Contact: "",
    Employer_Names_Surname: "",
    Employer_Contact: "",
    Bank_Name: "",
    Bank_Type: "",
    Picture: "",
    Status: "",
  });

  const BankTypeOptions = ["Please Select", "Savings", "Credit", "Business"];

  useEffect(() => {
    if (open) {
      setUser((prevUser) => ({
        ...prevUser,
        Status: "Create Full Profile",
      }));
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Input changed:", name, value); // Add this line for debugging
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    // Update ID_Passport separately
    if (name === "ID_Number_Passport") {
      setUser((prevUser) => ({
        ...prevUser,
        ID_Number_Passport: value,
      }));
    }
  };

  const handleSearchClick = async () => {
    try {
      // Ensure that ID_Passport is set before fetching data
      if (!user.ID_Number_Passport || !user.ID_Number_Passport.trim()) {
        console.error("ID_Passport is required for search");
        return;
      }

      const response = await fetch(
        `http://localhost:8082/${user.ID_Number_Passport}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error fetching customer data:", error.message);
      window.alert(
        "Please insert the correct ID or Passport. Please try again."
      );
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate each input field
      const requiredFields = [
        "ID_Number_Passport",
        "Contact_Number",
        "Other_Contact_Num",
        "Email_Address",
        "Home_Address",
        "Next_Of_kid_Names_Sur",
        "Next_Of_kid_Contact",
        "Employer_Names_Surname",
        "Employer_Contact",
        "Bank_Name",
        "Bank_Type",
        "Picture",
      ];

      for (const field of requiredFields) {
        if (!user[field] || !user[field].trim()) {
          console.error(`${field} is required`);
          alert(`${field} is required`);
          return;
        }
      }

      // Ensure that the status is "Create Full Profile"
      if (user.Status !== "Create Full Profile") {
        console.error("Invalid status for creating a full profile");
        alert(
          "Cannot create profile. The status must be 'Create Full Profile'. Please ensure the status is correct and try again."
        );
        return;
      }

      console.log("Submitting user data:", user);
      const apiUrl = "http://localhost:8084/add-full-profile";

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...user, Status: "Create Full Profile" }),
      });

      if (!response.ok) {
        throw new Error(`Failed to add client: ${response.statusText}`);
      }

      const responseData = await response.json();

      // Ensure that the response contains the expected data
      if (!responseData || !responseData.message) {
        throw new Error(
          "Failed to add client: Unexpected response from server"
        );
      }

      console.log(responseData.message);
      alert("Client added successfully");

      onSaveSuccess();
      setEditClient(null);
      onClose();
    } catch (error) {
      console.error("Error submitting client data:", error.message);
      alert(
        "This client's data already exists. Please contact the consultant for updates."
      );
    }
  };

  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setUser({ ...user, Picture: reader.result }); // Update key to match backend
      };
      reader.readAsDataURL(file);
    }
  };

  if (!open) return null;

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainerCreate"
      >
        <img src={nft} alt="/" />
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <h2 style={{ color: "black", marginBottom: "0%", margin: "10%" }}>
            Create Full Profile
          </h2>
          <div className="content">
            <h5>Search with ID/Passport</h5>
            <div className="inputColumns">
              <div className="inputColumn">
                <label htmlFor="">Customer ID/Passport</label>
                <input
                  type="text"
                  name="ID_Number_Passport"
                  placeholder={"Enter ID/Passport"}
                  value={user.ID_Number_Passport}
                  onChange={handleInputChange}
                />
              </div>
              <Button
                as="input"
                type="button"
                value="Search"
                className="BtnDecoration"
                style={{ backgroundColor: "darkgreen", color: "white" }}
                onClick={handleSearchClick}
              ></Button>
              <div className="inputColumn">
                <label htmlFor="">Full Names</label>
                <input
                  type="text"
                  name="Full_Names"
                  placeholder={"Full Names"}
                  value={user.Full_Names}
                  readOnly
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Surname</label>
                <input
                  type="text"
                  name="Surname"
                  placeholder={"Surname"}
                  value={user.Surname}
                  readOnly
                />
              </div>
            </div>
            <div className="inputColumns">
              <div className="inputColumn">
                <label htmlFor="">Status</label>
                <input
                  type="text"
                  name="Status"
                  placeholder={"Status"}
                  value={user.Status}
                  readOnly
                />
              </div>
            </div>
            <div className="inputColumns2">
              <div className="inputColumn">
                <label htmlFor="">Contact Number</label>
                <input
                  type="text"
                  name="Contact_Number"
                  placeholder={"Enter Contact Number"}
                  value={user.Contact_Number}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Other Contact Num</label>
                <input
                  type="text"
                  name="Other_Contact_Num"
                  placeholder={"Enter Other Contact Num"}
                  value={user.Other_Contact_Num}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Email Address</label>
                <input
                  type="text"
                  name="Email_Address"
                  placeholder={"Enter Email Address"}
                  value={user.Email_Address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Home Address</label>
                <input
                  type="text"
                  name="Home_Address"
                  placeholder={"Enter Home Address"}
                  value={user.Home_Address}
                  onChange={handleInputChange}
                />
              </div>

              <div className="inputColumn">
                <label htmlFor="">Next Of Kid Names & Sur</label>
                <input
                  type="text"
                  name="Next_Of_kid_Names_Sur"
                  placeholder={"Enter Next Of Kid Names & Surname"}
                  value={user.Next_Of_kid_Names_Sur}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="inputColumns3">
              <div className="inputColumn">
                <label htmlFor="">Next Of Kid Contact</label>
                <input
                  type="text"
                  name="Next_Of_kid_Contact"
                  placeholder={"Next Of Kid Contact"}
                  value={user.Next_Of_kid_Contact}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Employer Names & Surname</label>
                <input
                  type="text"
                  name="Employer_Names_Surname"
                  placeholder={"Enter Employer Names & Surname"}
                  value={user.Employer_Names_Surname}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Employer Contact</label>
                <input
                  type="text"
                  name="Employer_Contact"
                  placeholder={"Enter Employer Contact"}
                  value={user.Employer_Contact}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inputColumn">
                <label htmlFor="">Bank Name</label>
                <input
                  type="text"
                  name="Bank_Name"
                  placeholder={"Enter Bank Name"}
                  value={user.Bank_Name}
                  onChange={handleInputChange}
                />
              </div>

              <div className="inputColumn">
                <label htmlFor="">Bank Type</label>
                <select
                  name="Bank_Type"
                  value={user.Bank_Type}
                  onChange={handleInputChange}
                >
                  {BankTypeOptions.map((position, index) => (
                    <option key={index} value={position}>
                      {position}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="btnContainer">
            <button
              style={{
                marginLeft: "32px",
                marginBottom: "3%",
              }}
              className="btn-p green-button decorated-button"
              onClick={handleSubmit}
            >
              {"Create Full Profile"}
            </button>
          </div>
        </div>
        <div className="ImageUpload">
          <label htmlFor="upload-button" className="custom-file-upload"></label>
          <input
            type="file"
            accept="image/*"
            id="upload-button"
            className="upload-button"
            onChange={handleImageChange}
          />
          {image && (
            <div>
              <img src={image} alt="Preview" className="ImageContainer" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateFullProfileModal;
