import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Messages from "./pages/Messages";
import Analytics from "./pages/Analytics";
import Order from "./pages/Order";
import Calculate from "./pages/Calculate";
import Billing from "./pages/Billing";
import Profile from "./pages/Profile";
import Contact from "./pages/Contact";
import LoginUi from "./Components/Login";
import SideBar from "./Components/SideBar";
import Theme from "./pages/Theme";
import ProfileSettings from "./pages/ProfileSettings";
import NotificationsSettings from "./pages/Notifications";
import NotificationModal from "./Components/NotificationModal"; // Adjust the import path accordingly
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const handleLogin = (username, role) => {
    setIsLoggedIn(true);
    setUsername(username);
    setRole(role);
    window.location.hash = `#Signed%20in%3A%20${encodeURIComponent(username)}`;
  };

  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      const hash = `#Signed%20in%3A%20${encodeURIComponent(username)}`;
      if (window.location.hash !== hash) {
        window.location.hash = hash;
      }
    }
  }, [location, isLoggedIn, username]);

  return (
    <>
      <SpeedInsights />
      <Routes>
        <Route path="/login" element={<LoginUi onLogin={handleLogin} />} />
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <>
                <AuthenticatedRoutes username={username} role={role} />
                <NotificationModal
                  isOpen={modalVisible}
                  onClose={() => setModalVisible(false)}
                  username={username}
                  role={role}
                />
              </>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </>
  );
}

function AuthenticatedRoutes({ username, role }) {
  return (
    <SideBar username={username} role={role}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        {role === "CEO" && (
          <>
            <Route path="/users" element={<Users />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/order" element={<Order username={username} />} />
            <Route path="/calculate" element={<Calculate />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/file-manager/contact" element={<Contact />} />
            <Route path="/file-manager/billing" element={<Billing />} />
            <Route path="/file-manager/profile" element={<Profile />} />
            <Route path="/settings/theme" element={<Theme />} />
            <Route path="/settings/profile" element={<ProfileSettings />} />
            <Route
              path="/settings/notifications"
              element={<NotificationsSettings />}
            />
          </>
        )}
        {(role === "Consultant" || role === "Admin") && (
          <>
            <Route path="/calculate" element={<Calculate role={role} />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/order" element={<Order username={username} />} />
            <Route path="/file-manager/contact" element={<Contact />} />
            <Route path="/settings/theme" element={<Theme />} />
            <Route
              path="/settings/notifications"
              element={<NotificationsSettings />}
            />
          </>
        )}
        <Route path="*" element={<div>Not found</div>} />
      </Routes>
    </SideBar>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
