import React from "react";
import "./Style/Messages.css";

const Messages = () => {
  return (
    <div className="massage-container">
      <div className="title">Messages</div>
    </div>
  );
};

export default Messages;
