import React from "react";
import "./Style/Messages.css";

const Theme = () => {
  return (
    <div className="massage-container">
      <div className="title">Theme Settings</div>
    </div>
  );
};

export default Theme;
