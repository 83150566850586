import React from "react";

function generateLoanContract(
  lenderName,
  borrowerName,
  loanAmount,
  interestRate,
  numberOfInstallments,
  firstRepaymentDate,
  purpose
) {
  const currentDate = new Date().toLocaleDateString("en-US");

  // Replace placeholders with actual values
  const contractText = `
    <div style="font-family: Arial, sans-serif;">
      <p>${lenderName}</p>
      <p>[Company Address]</p>
      <p>[City, State, Zip Code]</p>
      <p>[Branch Name]</p>
      <p>[Email Address]</p>
      <p>[Phone Number]</p>
      <p>[Website URL]</p>
      <br />
      <p><strong>CONTRACT FOR LOAN AGREEMENT</strong></p>
      <br />
      <p>This Loan Agreement ("Agreement") is entered into as of ${currentDate}, by and between ${lenderName} ("Lender") and ${borrowerName} ("Borrower").</p>
      <br />
      <ol>
        <li><strong>LOAN AMOUNT:</strong> Lender agrees to lend Borrower the sum of R${loanAmount} ("Loan Amount") for the purpose of ${purpose}.</li>
        <li><strong>INTEREST:</strong> The Loan Amount shall accrue interest at a rate of ${interestRate}% per annum.</li>
        <li><strong>REPAYMENT:</strong> Borrower agrees to repay the Loan Amount in ${numberOfInstallments} equal monthly installments starting from ${firstRepaymentDate}, until the Loan Amount plus accrued interest is fully repaid.</li>
        <li><strong>DEFAULT:</strong> In the event of default by Borrower, Lender shall have the right to demand immediate repayment of the outstanding Loan Amount plus accrued interest, and to take any necessary legal action to enforce this Agreement.</li>
        <li><strong>GOVERNING LAW:</strong> This Agreement shall be governed by and construed in accordance with the laws of [State/Country], without regard to its conflicts of law principles.</li>
        <li><strong>ENTIRE AGREEMENT:</strong> This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof, and supersedes all prior and contemporaneous agreements and understandings, whether written or oral.</li>
      </ol>
      <br />
      <p>IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first above written.</p>
      <br />
      <p>${lenderName}</p>
      <p>By: ___________________________      Date: _______________</p>
      <br />
      <p>${borrowerName}</p>
      <p>By: ___________________________      Date: _______________</p>
    </div>
  `;

  return contractText;
}

const PrintContractButton = ({
  lenderName,
  borrowerName,
  loanAmount,
  interestRate,
  numberOfInstallments,
  firstRepaymentDate,
  purpose,
}) => {
  const handlePrintContract = () => {
    if (!purpose) {
      // If the purpose is not selected, display an alert message
      window.alert(
        "Please select a loan purpose before printing the contract."
      );
      return; // Exit the function early if the purpose is not selected
    }

    // Generate the contract text
    const contract = generateLoanContract(
      lenderName,
      borrowerName,
      loanAmount,
      interestRate,
      numberOfInstallments,
      firstRepaymentDate,
      purpose
    );

    // Create a new HTML document in memory
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
      <head>
        <title>Loan Agreement Contract</title>
      </head>
      <body>
        ${contract}
      </body>
    </html>
  `);
    printWindow.document.close();

    // Trigger the print dialog for the new document
    printWindow.print();
  };

  return (
    <div>
      <button
        className="BtnDecoration"
        style={{
          backgroundColor: "darkorange",
          color: "white",
          marginLeft: "10px",
          marginTop: "11px",
        }}
        onClick={handlePrintContract}
      >
        Print Contract
      </button>
    </div>
  );
};

export default PrintContractButton;
