import React from "react";
import "./Footer.css";
import Button from "react-bootstrap/Button";
import Logo from "./Videos/LocalLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="footer-container">
      <h4 className="H4"> Scroll down to discover more about our company. </h4>
      <div className="footer-subscription">
        <h2>Subscribe to Our Newsletter</h2>
        <form className="footer-form">
          <input
            className="footer-input"
            name="email"
            type="email"
            placeholder="Your Email"
          />
          <Button variant="dark" type="submit" className="footer-button">
            Subscribe
          </Button>
        </form>
      </div>
      <br></br>
      <div className="footer-content">
        <div className="footer-column">
          <h2>Contact Us</h2>
          <p>123 Street, City</p>
          <p>Jane furse</p>
          <p>Email: motekema@gmail.com</p>
          <p>Phone: +27 71 209 4678</p>
        </div>
        <div className="footer-column">
          <h2>Company Details</h2>
          <p>About Us</p>
          <p>Terms of Service</p>
          <p>Privacy Policy</p>
        </div>

        <div className="footer-column">
          <h2>Follow Us</h2>
          <div className="social-media-icons">
            <a href="https://www.instagram.com/motekema/">
              <FontAwesomeIcon icon={faInstagram} />
              <span>Instagram</span>
            </a>
            <a href="https://www.facebook.com/localITServices.co/">
              <FontAwesomeIcon icon={faFacebookF} />
              <span>Facebook</span>
            </a>
            <a href="https://www.twitter.com">
              <FontAwesomeIcon icon={faTwitter} />
              <span>Twitter</span>
            </a>
            <a
              href="https://wa.me/0712094678"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
              <span>WhatsApp</span>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-column">
        <img src={Logo} alt="Company Logo" className="footer-logo" />
      </div>
      <div className="footer-bottom">
        <p>© 2024 Company. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
