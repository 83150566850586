import React, { useState, useEffect } from "react";
import "./Style/Contact.css";
import { MessageCircle } from "react-feather";

function Contact() {
  const [contactData, setContactData] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "http://localhost:8084/customer_information"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      // Check if the data is an array before setting the state
      if (Array.isArray(data)) {
        setContactData(data);
      } else {
        console.error("Data fetched is not an array:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Optionally, update the state to indicate an error occurred
    }
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    // Trigger the search action when typing in the search input
    searchCustomer(e.target.value);
  };

  const searchCustomer = async (searchValue) => {
    try {
      // Search for Customer_ID based on the provided ID number
      const responseId = await fetch(
        `http://localhost:8084/customer_information/searchByPassport/${searchValue}`
      );
      if (!responseId.ok) {
        throw new Error("Failed to search customer by ID number");
      }
      const customerIdData = await responseId.json();
      console.log("Customer ID Data:", customerIdData); // Log the response data
      if (!customerIdData || customerIdData.length === 0) {
        // No customer found with the provided ID number
        setContactData([]);
        return;
      }
      // Extract the Customer_ID from the response data
      const customerId = customerIdData.customerId;
      console.log("Customer ID:", customerId); // Log the extracted Customer ID

      // Now, search for the customer details using the Customer_ID
      const responseDetails = await fetch(
        `http://localhost:8084/customer_details/${customerId}`
      );
      if (!responseDetails.ok) {
        throw new Error("Failed to fetch customer details");
      }
      const customerDetails = await responseDetails.json();
      console.log("Customer Details:", customerDetails); // Log the customer details
      setContactData(customerDetails); // Set the contact data to the fetched customer details directly
    } catch (error) {
      console.error("Error searching customer:", error.message);
      // Optionally, update the state to indicate an error occurred
    }
  };

  return (
    <div className="contact-container">
      <div className="title">Contact</div>
      <div>
        <div className="toolbar-contact">
          <input
            type="number"
            className="search-box"
            placeholder="Search...using ID"
            value={searchInput}
            onChange={handleSearchChange}
          />
          <button className="btn-p green-button decorated-button" type="button">
            <MessageCircle size={16} />
            <span>&nbsp;Send Message</span>
          </button>
        </div>
        <br />
        <div className="table-container">
          <table
            className="table"
            style={{ marginLeft: "-0px", width: "93%", marginBottom: "10px" }}
          >
            <thead>
              <tr>
                <th>Contact_Number</th>
                <th>Other_Contact_Num</th>
                <th>Email_Address</th>
                <th>Next_Of_kid_Names&Surname</th>
                <th>Next_Of_kid_Contact</th>
                <th>Employer_Names&Surname</th>
                <th>Employer_Contact</th>
                <th>Bank_Name</th>
                <th>Bank_type</th>
              </tr>
            </thead>
            <tbody>
              {contactData.length === 0 ? (
                <tr>
                  <td colSpan="9">
                    <div className="loading-container">
                      <div className="spinner"></div>
                      <span>Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                contactData.map((contact, index) => (
                  <tr key={index}>
                    <td>{contact.Contact_Number}</td>
                    <td>{contact.Other_Contact_Num}</td>
                    <td>{contact.Email_Address}</td>
                    <td>{contact.Next_Of_kid_Names_Sur}</td>
                    <td>{contact.Next_Of_kid_Contact}</td>
                    <td>{contact.Employer_Names_Surname}</td>
                    <td>{contact.Employer_Contact}</td>
                    <td>{contact.Bank_Name}</td>
                    <td>{contact.Bank_Type}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Contact;
