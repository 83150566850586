// src/calculations/loanCalculations.js

export const handleFormSubmit = async (
  e,
  ID_Number_Passport,
  Loan_Amount,
  Payment_Date,
  paymentType,
  interestFee,
  monthlyFee,
  adminFee,
  setCustomerLoanData,
  setRepayment_Amount
) => {
  e.preventDefault();

  if (!ID_Number_Passport) {
    window.alert("Please fill in the ID/Passport field.");
    return;
  }

  if (!Loan_Amount) {
    window.alert("Please fill in the Loan Amount field.");
    return;
  }

  if (!Payment_Date) {
    window.alert("Please fill in the Payment Date field.");
    return;
  }

  try {
    const response = await fetch(`http://localhost:8082/${ID_Number_Passport}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    setCustomerLoanData(data);

    const loanAmountValue = parseFloat(Loan_Amount);
    let calculatedCost = 0;

    // Convert percentage to decimal
    const interestFeeDecimal = interestFee / 100;
    const monthlyFeeDecimal = monthlyFee / 100;
    const adminFeeDecimal = adminFee / 100;

    if (paymentType === "Week") {
      // Assuming the fees are monthly, we divide by 4 for weekly payments
      calculatedCost =
        loanAmountValue *
        (1 + (interestFeeDecimal + monthlyFeeDecimal + adminFeeDecimal) / 4);
    } else if (paymentType === "Month") {
      calculatedCost =
        loanAmountValue *
        (1 + interestFeeDecimal + monthlyFeeDecimal + adminFeeDecimal);
    }

    setRepayment_Amount(calculatedCost);
  } catch (error) {
    console.error("Error fetching customer data:", error);
    setCustomerLoanData(null);
    window.alert("Please insert the correct ID or Passport. Please try again.");
  }
};
